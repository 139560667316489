import React from 'react';
import styles from './Card.module.css';

import { CardContext } from './CardContext';

interface Props {
  children?: React.ReactNode;
}

export default class HoverCardBody extends React.Component<Props> {
  public render() {
    const { children } = this.props;
    const hoverContext: { hover: boolean } = this.context as any;

    return (
      <>
        {hoverContext.hover && (
          <div className={styles.cardBody}>{children}</div>
        )}
      </>
    );
  }
}

HoverCardBody.contextType = CardContext;
