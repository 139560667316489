import * as React from 'react';
import styles from './LivePollList.module.css';
import Card from 'src/wick-ui/card/Card';
import CardBody from 'src/wick-ui/card/CardBody';
import { PrimaryButton } from 'src/components/button/PrimaryButton';
import { AddLivePollModal } from './components/add-livepoll-modal';
import { useLivePolls, useUserSetting } from 'src/hooks/store-hooks';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { useDeleteLivePollMutation } from 'src/hooks/store-hooks';
import livePollsLogoTitle from 'src/images/livepolls-logo-title.svg';
import { LivePollListTable } from './components/LivePollListTable';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import { LivePollListTopBar } from './components/LivePollListTopBar';
import { LivePollsView } from 'src/models/livepolls-view.enum';
import { Paginate } from 'src/models/paginate.enum';
import { TemplateRow } from 'src/components/template-row/TemplateRow';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { useTranslation } from 'react-i18next';

const defaultPagination = (): IPaginateDropdown => {
  return {
    pageIndex: Paginate.PAGE_INDEX,
    perPage: Paginate.PER_PAGE,
  };
};

export const LivePollListScreen = () => {
  const { t } = useTranslation();
  const [isAddModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const { data: userSetting, error: userSettingError } = useUserSetting();

  const [livePollsViewType] = React.useState<LivePollsView>(LivePollsView.LIST);
  const [sortingParams, setSortingParams] =
    React.useState<IFetchLivePollsSortingParams>({});
  const [pager, setPager] = React.useState<IPaginateDropdown>(
    defaultPagination(),
  );

  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [areLivePollsPresent, setAreLivePollsPresent] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setSortingParams({
      sortBy: userSetting?.sortBy,
      sortOrder: userSetting?.sortOrder,
    });
  }, [userSetting]);

  const handleToggleAddLivePoll = React.useCallback(() => {
    setAddModalOpen(!isAddModalOpen);
  }, [isAddModalOpen]);

  const handleLivePollListSort = (
    sortingParams: IFetchLivePollsSortingParams,
  ) => {
    setSearchText(sortingParams.title || null);
    pager.pageIndex > 1 && setPager({ pageIndex: 1, perPage: pager.perPage });
    setSortingParams({ ...sortingParams });
  };

  const {
    isError,
    isLoading,
    error,
    data: livePollsPaginate,
  } = useLivePolls(sortingParams, livePollsViewType, pager);

  const {
    mutate: deleteLivePollMutation,
    isLoading: isLivePollDeleteRunning,
    isError: isDeleteLivePollError,
    error: deleteLivePollError,
  } = useDeleteLivePollMutation();

  React.useEffect(() => {
    if (!areLivePollsPresent) {
      const isPresent = !!(
        livePollsPaginate?.livePolls && livePollsPaginate.livePolls.length > 0
      );
      isPresent && setAreLivePollsPresent(true);
    }
  }, [areLivePollsPresent, livePollsPaginate]);

  if (userSettingError) throw userSettingError;

  if (isError) {
    throw error;
  }

  if (isDeleteLivePollError) {
    throw deleteLivePollError;
  }

  if (isLivePollDeleteRunning) {
    return <LpSpinner message={`${t('deleting')} ${t('livePoll')}`} />;
  }

  const handleDeleteLivePoll = (e: React.MouseEvent, livePollId: number) => {
    e.stopPropagation();
    const isConfirmed: Boolean = window.confirm(t('confirmDelete'));
    if (isConfirmed) {
      deleteLivePollMutation({
        livePollId,
        livePollsQueryParams: {
          sortBy: sortingParams.sortBy,
          sortOrder: sortingParams.sortOrder,
          viewType: livePollsViewType,
          pageIndex: pager.pageIndex,
          perPage: pager.perPage,
        },
      });
    }
  };

  const handlePageChange = (pager: IPaginateDropdown) => {
    setPager({ ...pager });
  };

  const createLivePollCard = (
    <div className={styles.createLivePollCard}>
      <Card selectable={true} onClick={handleToggleAddLivePoll}>
        <CardBody>
          <div className={styles.primaryButtonContainer}>
            <PrimaryButton text={`${t('createNewLivePoll')}`} />
          </div>
        </CardBody>
      </Card>
    </div>
  );

  let content = <LpSpinner message={`${t('loadingLivePolls')}...`} />;

  if (!isLoading) {
    if (areLivePollsPresent) {
      content = (
        <div className={styles.homeScreenContainer}>
          <LivePollListTopBar onAddNewLivePoll={handleToggleAddLivePoll} />
          <div className={styles.containerWrapper}>
            <div className={styles.container}>
              <TemplateRow />
              <div className={styles.lineSeparator}></div>
              <LivePollListTable
                sortingParams={sortingParams}
                pager={pager}
                totalItems={livePollsPaginate?.totalItems!}
                livePolls={livePollsPaginate?.livePolls}
                onTableHeadClick={handleLivePollListSort}
                onDeleteIconClick={handleDeleteLivePoll}
                onPageChange={handlePageChange}
                searchText={searchText}
              />
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <>
          <div className={styles.emptyState}>
            <TemplateRow backgroundColor="#d8d8d8" />
          </div>
          <div className={styles.welcomeContainer}>
            <span className={styles.welcomeMessage}>{t('welcomeTo')}</span>
            <img
              className={styles.welcomeImage}
              src={livePollsLogoTitle}
              height="20px"
              alt="LivePolls home page"
            />
            {createLivePollCard}
          </div>
        </>
      );
    }
  }

  return (
    <>
      {content}
      {isAddModalOpen && (
        <AddLivePollModal onCloseModal={handleToggleAddLivePoll} />
      )}
    </>
  );
};
