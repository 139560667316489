import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './Button.module.css';

interface Props {
  onClick?: () => void;
  text: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  secondary?: boolean;
  customBtnClass?: string;
  leftIcon?: IconProp;
  iconSize?: SizeProp;
}

export default class Button extends React.Component<Props> {
  public render() {
    const {
      text,
      type,
      disabled,
      secondary,
      leftIcon,
      iconSize,
      customBtnClass,
    } = this.props;

    return (
      <button
        disabled={disabled}
        type={type ? type : 'button'}
        className={
          styles.btn +
          ' ' +
          (secondary ? styles.secondary : '') +
          ' ' +
          (customBtnClass ? customBtnClass : '')
        }
        onClick={this.handleOnClick}
      >
        {leftIcon && (
          <FontAwesomeIcon
            icon={leftIcon}
            size={iconSize ? iconSize : '1x'}
            className={styles.leftIcon}
          />
        )}
        {text}
      </button>
    );
  }

  private handleOnClick = () => {
    const { disabled, onClick } = this.props;
    if (disabled || !onClick) {
      return;
    }

    // Call the onClick
    onClick();
  };
}
