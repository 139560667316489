import { NavigateFunction, NavigateOptions } from 'react-router-dom';

export const URL_PREFIX: string = '/';
export const MEETING_APP_URL_PREFIX: string = `${URL_PREFIX}meeting/apps/`;

export const URLS = Object.freeze({
  LIVE_POLL_LIST_URL: 'livepolls',
  EDIT_LIVE_POLL_URL: 'livepolls/:livePollId',
  SESSIONS_SESSION_LIST: 'livepolls/:livePollId/sessions',
  ANALYTICS_SESSION_LIST: 'livepolls/:livePollId/analytics',
  LIVEPOLL_STATS: 'livepolls/admin/stats',
  LIVE_POLL_SESSION_DASHBOARD:
    'livepolls/:livePollId/sessions/:livePollSessionId/dashboard',
  ANALYTICS_SESSION_DASHBOARD:
    'livepolls/:livePollId/analytics/:sessionId/dashboard',
  ANALYTICS_SESSION_PROFILE_DASHBOARD:
    'livepolls/:livePollId/sessions/:sessionId/profile-dashboard',

  ANALYTICS_SESSION_LEADERBOARD:
    'livepolls/:livePollId/sessions/:sessionId/leaderboard',

  TAKE_LIVE_POLL_SETTING_URL: 'setting/:livePollId',
  GOOGLE_SLIDES_URL: 'slides/:livePollId',
  TAKE_LIVE_POLL_URL: `/livepolls/:livePollId/livepoll-sessions/:livePollSessionId/run`,
  FINISH_TAKE_LIVE_POLL_URL: `/livepolls/:livePollId/livepoll-sessions/:livePollSessionId/finish-session`,

  PRESENTER_VIEW_WINDOW_URL: `/run/window`,
  EDIT_REGULAR_LIVE_POLL_URL: 'livepolls/:livePollId/edit',
  EDIT_PROFILE_LIVE_POLL_URL: 'livepolls/:livePollId/edit-profile',

  SETTINGS_LIVE_POLL_URL: 'livepolls/:livePollId/settings',
  DESIGN_LIVE_POLL_URL: 'livepolls/:livePollId/design',
  LIVE_POLL_EDITOR_FROM_TEMPLATE: 'template',
  ADMIN_PERFORMANCE_LOGS_URL: 'admin/logs',
  PERMANENT_ROOM_SESSION_DASHBOARD:
    'livepolls/:livePollId/livepoll-sessions/:livePollSessionId/permanent-room/dashboard',
  GOOGLE_SLIDES_LOGIN_SUCCESS: 'google-slides/login/success',
  LIVE_POLL_EDITOR_FOR_SLIDES_URL: 'livepolls/:livePollId/google-slides/editor',
  LIVE_POLL_LIST_FOR_SLIDES_URL: 'livepolls/google-slides/home',
  LIVE_POLL_DESIGN_URL_FOR_SLIDES: 'livepolls/:livePollId/google-slides/design',
  TAKE_LIVE_POLL_FOR_SLIDES_URL: `/livepolls/:livePollId/livepoll-sessions/:livePollSessionId/google-slides/run`,
});

export const MEETING_APPS_URLS = Object.freeze({
  LIVEPOLL_MEETING_APPS: MEETING_APP_URL_PREFIX,
  ZOOM_APP_HOME: 'zoom',
  PRESENTER_LOGIN_ZOOM: 'zoom/presenter/login',
});

export function goToLivePollEditURL(
  navigateFn: NavigateFunction,
  id: number,
  profileQuestions?: boolean,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(
      `${URLS.LIVE_POLL_LIST_URL}/${id}/` +
        `${profileQuestions === true ? 'editprofile' : ''}`,
    ),
    navigateFn,
  );
}

export function goToLivePollEditURLForSlides(
  navigateFn: NavigateFunction,
  id: number,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(
      `${URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL.replace(':livePollId', `${id}`)}`,
    ),
    navigateFn,
  );
}

export function goToLivePollListURL(navigateFn: NavigateFunction) {
  dispatchActiveRouteChanged(
    getPrefixedURL(URLS.LIVE_POLL_LIST_URL),
    navigateFn,
  );
}

export function goToLivePollSettingsURL(
  navigateFn: NavigateFunction,
  livePollId: number,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(`livepolls/${livePollId}/settings`),
    navigateFn,
  );
}
export function gotoLivePollStats(navigateFn: NavigateFunction) {
  dispatchActiveRouteChanged(
    getPrefixedURL(`livepolls/admin/stats`),
    navigateFn,
  );
}

export function gotoLivePollSessionDashboard(
  livePollId: number,
  livePollSessionId: number,
  navigateFn: NavigateFunction,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(
      `livepolls/${livePollId}/sessions/${livePollSessionId}/dashboard`,
    ),
    navigateFn,
  );
}

export function goToTakeLivePoll(
  navigateFn: NavigateFunction,
  livePollId: number,
  livePollSessionId: number,
) {
  dispatchActiveRouteChanged(
    `/livepolls/${livePollId}/livepoll-sessions/${livePollSessionId}/run`,
    navigateFn,
  );
}

export function goToTakeLivePollSessionsURL(
  navigateFn: NavigateFunction,
  id: number,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(`livepolls/${id}/sessions`),
    navigateFn,
  );
}

export function goToTakeLivePollSettingsURL(
  navigateFn: NavigateFunction,
  id: number,
) {
  dispatchActiveRouteChanged(getPrefixedURL(`setting/${id}`), navigateFn);
}

export function goToFinishTakeLivePollURL(
  navigateFn: NavigateFunction,
  livePollId: number,
  livePollSessionId: number,
) {
  dispatchActiveRouteChanged(
    getPrefixedURL(
      `livepolls/${livePollId}/livepoll-sessions/${livePollSessionId}/finish-session`,
    ),
    navigateFn,
    { replace: true },
  );
}

function getPrefixedURL(url: string): string {
  return URL_PREFIX + url;
}

// Make sure we update the new url in Redux store so that nav is updated
function dispatchActiveRouteChanged(
  url: string,
  navigateFn: NavigateFunction,
  navigateFnOptions?: NavigateOptions,
) {
  if (navigateFnOptions != null) {
    navigateFn(url, { ...navigateFnOptions });
    return;
  }

  navigateFn(url, navigateFnOptions);
}
