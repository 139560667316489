import * as React from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from '../../utils/hooks.util';
import styles from './LpDropdown.module.css';

interface Props {
  text: string;
  children: React.ReactNode;
  leftIcon?: any;
  leftColor?: string | null;
  customDropdownBtnClass?: string;
  customDropdownContentClass?: string;
  customDropdownTextClass?: string;
}

export const LpDropdown = ({
  text,
  leftIcon,
  customDropdownBtnClass,
  leftColor,
  customDropdownContentClass,
  customDropdownTextClass,
  children,
}: Props) => {
  const dropDownRef = React.useRef<HTMLDivElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = React.useState<boolean>(false);
  const dropdownBtnRef = React.useRef<HTMLButtonElement | null>(null);

  useOnClickOutside(dropDownRef, dropdownBtnRef, () => {
    setIsDropDownOpen(false);
  });

  const handleToggleDropDown = () => {
    setIsDropDownOpen(prevState => !prevState);
  };
  return (
    <div className={styles.dropdown}>
      <button
        className={`${styles.dropDownBtn} ${customDropdownBtnClass}`}
        onClick={handleToggleDropDown}
        ref={dropdownBtnRef}
      >
        {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {leftColor && (
          <span
            className={styles.leftColor}
            style={{ backgroundColor: leftColor }}
          ></span>
        )}
        <div className={`${styles.dropdownText} ${customDropdownTextClass}`}>
          {text}
        </div>
        <span className={styles.dropdownIcon}>
          <FontAwesomeIcon icon={isDropDownOpen ? faCaretUp : faCaretDown} />
        </span>
      </button>
      {isDropDownOpen && (
        <div
          className={`${styles.dropdownContent} ${customDropdownContentClass}`}
          onClick={handleToggleDropDown}
          ref={dropDownRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};
