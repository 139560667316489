export const PollSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 -2.5083e-05C1.55228 -2.5083e-05 2 0.44769 2 0.999975V12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H0V0.999975C0 0.44769 0.447715 -2.5083e-05 1 -2.5083e-05ZM12.1056 0.552761C12.3526 0.0587828 12.9532 -0.141442 13.4472 0.105548C13.9412 0.352537 14.1414 0.95321 13.8944 1.44719L10.8944 7.44719C10.5259 8.18424 9.4741 8.18424 9.10557 7.44719L8 5.23604L5.89443 9.44719C5.64744 9.94117 5.04676 10.1414 4.55279 9.8944C4.05881 9.64741 3.85858 9.04674 4.10557 8.55276L7.10557 2.55276C7.4741 1.81571 8.5259 1.81571 8.89443 2.55276L10 4.76391L12.1056 0.552761Z"
        fill="currentColor"
      />
    </svg>
  );
};
