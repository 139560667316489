/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(minInclusive: number, maxInclusive: number) {
  minInclusive = Math.ceil(minInclusive);
  maxInclusive = Math.floor(maxInclusive);
  return (
    Math.floor(Math.random() * (maxInclusive - minInclusive + 1)) + minInclusive
  );
}

/**
 * If the num is less then 1000 returns the num as string
 * Else return the string in format 1K, 5.5K etc
 */
export function convertToHumanReadableFormat(num: number): string {
  if (num < 1000) return `${num}`;

  return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
}
