import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { IAnswer } from '@livepolls/ui-components/src/interfaces/answer.interface';
import { ChatGptGeneratePresenterNotesResponse } from '@livepolls/ui-components/src/interfaces/chatgpt-generate-presenter-notes-response.interface';
import { ILiveResultChartType } from '@livepolls/ui-components/src/interfaces/live-result-type.interface';
import { IPresenterNotes } from '@livepolls/ui-components/src/interfaces/presenter-notes.interface';
import { ISwipeQuestionUpdates } from 'src/hooks/interfaces/swipe-question-updates.interface';
import { IProfileQuestionWithAnswers } from 'src/models/profile-question-with-answer.interface';
import { patchApi, deleteApi, postApi, getApi } from './api.util';
import { v4 as uuid } from 'uuid';
import { IProfileQuestionAnswer } from 'src/models/profile-question-answer.interface';
import { IQuestion } from '@livepolls/ui-components/src/interfaces/question.interface';
import { IPermanentRoom } from 'src/models/permanent-room.interface';
import { IProfileQuestion } from 'src/models/profile-question.interface';
import { IAddQuestion } from 'src/models/add-question.interface';
import { IAddGeneratedQuestions } from 'src/models/add-generated-questions.interface';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { IAddProfileQuestion } from 'src/models/add-profile-question.interface';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { IChoiceQuestion } from '@livepolls/ui-components/src/interfaces/choice-question.interface';
import { t } from 'i18next';
import { ILiveFeedQuestion } from 'src/models/live-feed-question.interface';
import { ISwipeQuestion } from 'src/models/swipe-question.interface';
import { IWordCloudQuestion } from 'src/models/word-cloud-question.interface';
import { SwipeQuestionCardSide } from 'src/models/swipe-question-card-side.enum';

export const updateQuestion = (
  livePollId: number,
  questionId: string,
  updates: {
    title?: string;
    answers?: IAnswer[];
    image?: { url: string };
    difficultyLevel?: QuestionDifficultyLevel;
    responseLimit?: number;
    liveResultChartType?: ILiveResultChartType;
    isMultiSelect?: boolean;
    presenterNotes?: IPresenterNotes;
    swipeQuestionUpdates?: ISwipeQuestionUpdates;
  },
): Promise<void> => {
  return patchApi(
    `/edit/livepolls/${livePollId}/questions/${questionId}`,
    updates,
  );
};

export const deleteQuestionFact = (
  livePollId: number,
  questionId: string,
  id: string,
): Promise<void> => {
  return deleteApi(
    `/edit/livepolls/${livePollId}/questions/${questionId}/question-fact/${id}`,
  );
};

export const addQuestionFactsWithAi = (
  livePollId: number,
  questionId: string,
  questionTitle: string,
): Promise<ChatGptGeneratePresenterNotesResponse | undefined> => {
  return patchApi(
    `/edit/livepolls/${livePollId}/questions/${questionId}/generate-question-facts`,
    { questionTitle },
  );
};

export const updateAIGeneratedQuestionsReviewStatus = (
  livePollId: number,
): Promise<void> => {
  return patchApi(`/edit/livepolls/${livePollId}/questions`, {});
};

export const copyProfileQuestionFrom = (
  profileQuestion: IProfileQuestionWithAnswers,
): IProfileQuestionWithAnswers => {
  const newProfileQuestion =
    createNewProfileQuestion() as IProfileQuestionWithAnswers;
  newProfileQuestion.isRandomizeAnswers = profileQuestion.isRandomizeAnswers;

  newProfileQuestion.title = profileQuestion.title;
  if (profileQuestion.image) {
    newProfileQuestion.image = { ...profileQuestion.image };
  }
  // create a deep copy of answers array and assign a new id to answer
  newProfileQuestion.answers = profileQuestion.answers.map(answer => {
    return { ...answer, id: uuid() };
  });
  return newProfileQuestion;
};
export const updateProfileQuestion = (
  livePollId: number,
  profileQuestionId: string,
  updates: {
    title?: string;
    answers?: IProfileQuestionAnswer[];
    image?: { url: string };
  },
): Promise<void> => {
  return patchApi(
    `/edit/livepolls/${livePollId}/profile-questions/${profileQuestionId}`,
    updates,
  );
};

export const updatePermanentRoom = (
  livePollId: number,
  roomId: number,
  enable: boolean,
  sessionId?: number,
  livePollTitle?: string,
): Promise<IPermanentRoom> => {
  return patchApi(`/take/${livePollId}/room/${roomId}`, {
    enable,
    sessionId,
    livePollTitle,
  });
};

export const reorderQuestions = (
  livePollId: number,
  questions: IQuestion[],
): Promise<void> => {
  return patchApi(`/edit/livepolls/${livePollId}`, { questions });
};

export const reorderProfileQuestions = (
  livePollId: number,
  profileQuestions: IProfileQuestion[],
): Promise<void> => {
  return patchApi(`/edit/livepolls/${livePollId}`, { profileQuestions });
};
export const deleteQuestion = (
  livePollId: number,
  questionId: string,
): Promise<void> => {
  return deleteApi(`/edit/livepolls/${livePollId}/question/${questionId}`);
};

export const setLiveResultChartType = (
  livePollId: number,
  questionId: string,
  liveResultChartType: ILiveResultChartType,
): Promise<void> => {
  return patchApi(
    `/edit/livepolls/${livePollId}/questions/${questionId}/update-live-result-chart-type`,
    { liveResultChartType },
  );
};
export const deleteProfileQuestion = (
  livePollId: number,
  profileQuestionId: string,
): Promise<void> => {
  return deleteApi(
    `/edit/livepolls/${livePollId}/profile-questions/${profileQuestionId}`,
  );
};

export const addQuestion = (request: IAddQuestion): Promise<void> => {
  const { livePollId, ...data } = request;
  return postApi(`/edit/livepolls/${livePollId}/questions`, data);
};

export const addAIGeneratedQuestions = (
  updates: IAddGeneratedQuestions,
): Promise<void> => {
  const { livePollId } = updates;
  return postApi(
    `/edit/livepolls/${livePollId}/questions/add-generated-questions`,
    { ...updates },
  );
};

export const addProfileQuestion = (
  request: IAddProfileQuestion,
): Promise<void> => {
  const { livePollId, ...data } = request;
  return postApi(`/edit/livepolls/${livePollId}/profile-questions`, data);
};

export const predictQuestionsFromText = (
  text: string,
  questionsRequired: number,
  keyPhrases: Array<string>,
  livepollId: number,
): Promise<{
  error: string;
  result: {
    id_text: number;
    questions: Array<string>;
    status: string;
    text: string;
    unique_id: string;
  };
}> => {
  const data = {
    text,
    questions_required: questionsRequired,
    key_phrases: keyPhrases,
  };
  return postApi(
    `/edit/livepolls/${livepollId}/questions/generate_question`,
    data,
  );
};

export const getChatGptQuestions = (
  livepollId: number,
  topic: string,
  type: LivePollType,
): Promise<string> => {
  return getApi(
    `/edit/livepolls/${livepollId}/questions/get-questions-from-chatgpt`,
    {
      params: { topic, type },
    },
  );
};

export const createNewQuestion = (
  type: QuestionType,
  optionText?: string,
  livePollType?: LivePollType,
): IQuestion => {
  switch (type) {
    case QuestionType.CHOICE:
      return {
        id: uuid(),
        type,
        isRandomizeAnswers: false,
        answers: [
          // mark first answer as correct for quiz
          // this is to make the process of marking answers more intuitive for users
          {
            id: uuid(),
            isCorrect: true,
            text: `${optionText ? optionText : 'Option'} 1`,
          },
          {
            id: uuid(),
            isCorrect: false,
            text: `${optionText ? optionText : 'Option'} 2`,
          },
        ],
      } as IChoiceQuestion;
    case QuestionType.LIVE_FEED:
      return {
        id: uuid(),
        type,
        image: { url: '' },
      };
    case QuestionType.WORD_CLOUD:
      return {
        id: uuid(),
        type,
        responseLimit: 1,
      } as IWordCloudQuestion;

    case QuestionType.SWIPE:
      return {
        id: uuid(),
        type,
        title:
          livePollType === LivePollType.QUIZ
            ? t('swipeQuestionTitleForQuiz')
            : t('swipeQuestionTitleForPoll'),
        cards: createSwipeQuestionCards(livePollType!),
        swipeOptions: createSwipeQuestionOptions(livePollType!),
      } as ISwipeQuestion;

    default:
      throw new Error('Incorrect question type');
  }
};

export const createNewProfileQuestion = (
  optionText?: string,
): IProfileQuestion => {
  return {
    id: uuid(),
    type: 0,
    isRandomizeAnswers: false,
    answers: [
      { id: uuid(), text: `${optionText ? optionText : 'Option'} 1` },
      { id: uuid(), text: `${optionText ? optionText : 'Option'} 2` },
    ],
  } as IProfileQuestion;
};

export const copyQuestionFrom = (question: IQuestion): IQuestion => {
  switch (question.type) {
    case QuestionType.CHOICE:
      const choiceQuestion = question as IChoiceQuestion;
      const newChoiceQuestion = createNewQuestion(
        choiceQuestion.type,
      ) as IChoiceQuestion;
      newChoiceQuestion.title = choiceQuestion.title;
      newChoiceQuestion.isRandomizeAnswers = choiceQuestion.isRandomizeAnswers;
      if (choiceQuestion.image) {
        newChoiceQuestion.image = { ...choiceQuestion.image };
      }

      // create a deep copy of answers array and assign a new id to answer
      newChoiceQuestion.answers = choiceQuestion.answers.map(answer => {
        return { ...answer, id: uuid() };
      });
      return newChoiceQuestion;
    case QuestionType.LIVE_FEED:
      const newLiveFeedQuestion = createNewQuestion(
        question.type,
      ) as ILiveFeedQuestion;
      newLiveFeedQuestion.title = question.title;
      return newLiveFeedQuestion;
    case QuestionType.WORD_CLOUD:
      const wordCloudQuestion = question as IWordCloudQuestion;
      const newWordCloudQuestion = createNewQuestion(
        wordCloudQuestion.type,
      ) as IWordCloudQuestion;
      newWordCloudQuestion.title = wordCloudQuestion.title;
      newWordCloudQuestion.responseLimit = wordCloudQuestion.responseLimit;
      return newWordCloudQuestion;

    case QuestionType.SWIPE:
      const swipeQuestion = question as ISwipeQuestion;
      const newSwipeQuestion = createNewQuestion(
        swipeQuestion.type,
      ) as ISwipeQuestion;
      newSwipeQuestion.cards = swipeQuestion.cards;
      newSwipeQuestion.swipeOptions = swipeQuestion.swipeOptions;
      newSwipeQuestion.title = swipeQuestion.title;
      return newSwipeQuestion;
    default:
      throw new Error('Incorrect question type');
  }
};
const createSwipeQuestionCards = (livePollType: LivePollType) => {
  const cards = [];
  cards.push(createSwipeQuestionCard(livePollType, 1));
  cards.push(createSwipeQuestionCard(livePollType, 2));
  return cards;
};

const createSwipeQuestionCard = (
  livePollType: LivePollType,
  cardNumber: number,
) => {
  if (livePollType === LivePollType.QUIZ) {
    return {
      id: uuid(),
      title:
        cardNumber === 1
          ? 'The two longest rivers in the world are the Mississippi and the Nile'
          : 'WWW stands for “world wide web”',
      image: { url: '' },
      correctSide:
        cardNumber === 1
          ? SwipeQuestionCardSide.LEFT
          : SwipeQuestionCardSide.RIGHT,
    };
  }
  return {
    id: uuid(),
    title: cardNumber === 1 ? 'Red apples' : 'Bananas',
    image: { url: '' },
  };
};

const createSwipeQuestionOptions = (livePollType: LivePollType) => {
  const options = {
    right: {
      text: livePollType === LivePollType.QUIZ ? 'True' : 'Like',
      color: '#3FCA7F',
    },
    left: {
      text: livePollType === LivePollType.QUIZ ? 'False' : 'Dislike',
      color: '#FF7681',
    },
  };

  return options;
};
