import { useOptAuth } from './contexts/auth.context';
import { AuthenticatedApp } from './authenticated-app';
import { UnauthenticatedApp } from './unauthenticated-app';

function App() {
  const { user } = useOptAuth();
  return (
    <>
      {user && <AuthenticatedApp />}
      {!user && <UnauthenticatedApp />}
    </>
  );
}

export default App;
