import * as React from 'react';
import styles from './Modal.module.css';
import Button from '../button/Button';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { withTranslation, WithTranslation } from 'react-i18next';
import closeButton from '../../images/close.svg';

interface Props extends WithTranslation {
  title: string;
  closePopup: () => void;
  onSubmit?: () => void;
  submitText?: string;
  disableSubmitBtn?: boolean;
  hideCloseBtn?: boolean;
  loading?: boolean;
  popupWidth?: number;
  footerLeftSideText?: string;
  popupContentClass?: string;
  children?: React.ReactNode;
}

interface ModalState {}

class Modal extends React.Component<Props, ModalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayDrillDown: false,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleEsc, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    document.removeEventListener('keydown', this.handleEsc, false);
  }

  public handleEsc(event: any) {
    if (event.keyCode === 27) {
      if (this.props && this.props.closePopup) {
        this.props.closePopup();
      }
    }
  }
  public handleClickOutside(event: any) {
    if (event.target.id === 'wick-ui-modal-id') {
      if (this.props.closePopup) {
        this.props.closePopup();
      }
    }
  }

  public render() {
    const {
      onSubmit,
      submitText = 'Continue',
      disableSubmitBtn,
      popupContentClass,
    } = this.props;

    return (
      <div id="wick-ui-modal-id" className={styles.popup}>
        <div
          className={styles.popup_inner}
          style={{
            maxWidth: `${
              this.props.popupWidth ? this.props.popupWidth + 'px' : '48rem'
            }`,
          }}
        >
          <div className={styles.popupHeading}>
            <div className={styles.headerText} role="heading" aria-level={2}>
              {this.props.title || <pre> </pre>}
            </div>
            <span>{this.renderCloseBtn()}</span>
          </div>
          <div
            className={`${
              popupContentClass ? popupContentClass : styles.popupContent
            }`}
          >
            {this.props.children}

            {onSubmit && (
              <>
                <div className={styles.horizontalLine}></div>
                <div className={styles.submitButtonContainer}>
                  {!this.props.loading && (
                    <>
                      <div
                        className={styles.footerLeftText}
                        data-testid="modalFooterLeft"
                      >
                        {this.props.footerLeftSideText && (
                          <span>{this.props.footerLeftSideText}</span>
                        )}
                      </div>
                      <div className={styles.cancelAndSubmitBtn}>
                        <div
                          onClick={this.props.closePopup}
                          className={styles.cancelLink}
                        >
                          {this.props.t('cancel')}
                        </div>
                        <Button
                          disabled={disableSubmitBtn}
                          text={submitText}
                          onClick={onSubmit}
                        />
                      </div>
                    </>
                  )}
                  {this.props.loading && (
                    <div className={styles.spinnerPosition}>
                      <LpSpinner customSpinnerClass={styles.spinner} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  private renderCloseBtn() {
    const { hideCloseBtn } = this.props;
    if (hideCloseBtn) {
      return null;
    }

    return (
      <span
        aria-label="Modal close button"
        className={styles.close}
        onClick={this.props.closePopup}
      >
        <img src={closeButton} alt="close modal" />
      </span>
    );
  }
}

export default withTranslation()(Modal);
