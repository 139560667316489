import React from 'react';
import styles from './ColorPicker.module.css';

interface Props {
  colorInHex: string;
  onChange: (selectedColorInHex: string) => void;
  customClass?: string;
}

export const ColorPicker = ({ colorInHex, onChange, customClass }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={styles.colorPicker}>
      <input
        data-testid="color-picker-input"
        type="color"
        value={colorInHex}
        onChange={event => {
          handleChange(event);
        }}
      />
    </div>
  );
};
