import React from 'react';
import styles from './Card.module.css';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import Icon from '../icon/Icon';
import ReactDOM from 'react-dom';

interface State {
  state: number;
}

interface Props {
  children?: React.ReactNode;
}

const STATES = Object.freeze({
  DEFAULT: 1,
  OPEN: 2,
  CLOSE: 3,
});

export default class CardDrawer extends React.Component<Props, State> {
  wrapperRef: Node | undefined;
  constructor(props: Props) {
    super(props);
    this.state = {
      state: STATES.DEFAULT,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  private handleClickOutside = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (
      domNode &&
      !domNode.contains(event.target) &&
      this.state.state === STATES.OPEN
    ) {
      setTimeout(() => {
        this.setState({ state: STATES.CLOSE });
      }, 100);
    }
  };

  public render() {
    const { children } = this.props;
    const { state } = this.state;

    const closeState = state === STATES.CLOSE || state === STATES.DEFAULT;
    return (
      <>
        <div className={this.getCssClass()} onClick={this.stopClickPropagation}>
          {children}
        </div>
        <span className={this.getToggleButtonCssClass()}>
          <Icon
            rotate={true}
            dark={true}
            icon={closeState ? faEllipsisH : faTimes}
            title={
              closeState ? 'Open LivePoll Settings' : 'Close LivePoll Settings'
            }
            onClick={this.toggleDrawer}
          />
        </span>
      </>
    );
  }

  private stopClickPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  private getToggleButtonCssClass() {
    const { state } = this.state;
    if (state === STATES.OPEN) {
      return styles.cardDrawerToggle;
    } else if (state === STATES.CLOSE) {
      return styles.cardDrawerToggleOpen;
    } else {
      return styles.cardDrawerToggle;
    }
  }

  private getCssClass() {
    const { state } = this.state;
    if (state === STATES.OPEN) {
      return styles.cardDrawerOpen;
    } else if (state === STATES.CLOSE) {
      return styles.cardDrawerClose;
    }
    return styles.cardDrawer;
  }

  private toggleDrawer = (e: React.MouseEvent) => {
    const { state } = this.state;

    if (state === STATES.OPEN) {
      this.setState({ state: STATES.CLOSE });
    } else {
      this.setState({ state: STATES.OPEN });
    }
    e.stopPropagation();
  };
}
