import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './PrimaryButton.module.css';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
interface Props {
  text: string;
  onClick?: () => Promise<void>;
  size?: SizeProp;
}

interface State {
  clickProcessing: boolean;
}

export class PrimaryButton extends React.Component<Props, State> {
  private cancelClickProcessing = false;
  constructor(props: Props) {
    super(props);
    this.state = {
      clickProcessing: false,
    };
  }

  public componentWillUnmount() {
    this.cancelClickProcessing = true;
  }

  public render() {
    let { size } = this.props;
    const { text, onClick } = this.props;
    const { clickProcessing } = this.state;

    if (!size) {
      size = '2x';
    }

    return (
      <button
        data-testid="primary-button"
        aria-label={text}
        className={styles.container}
        onClick={onClick ? this.handleClick : undefined}
      >
        {!clickProcessing && (
          <FontAwesomeIcon
            icon={faPlus}
            title={text}
            size={size}
            className={styles.primary}
          />
        )}
        {clickProcessing && <LpSpinner />}
      </button>
    );
  }

  private handleClick = () => {
    const { clickProcessing } = this.state;
    const { onClick } = this.props;
    // Clicked again, ignore
    if (clickProcessing || !onClick) {
      return;
    }

    this.setState({ clickProcessing: true });
    onClick().then(() => {
      if (!this.cancelClickProcessing) {
        this.setState({ clickProcessing: false });
      }
    });
  };
}
