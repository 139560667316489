export const API_URL = process.env.REACT_APP_API_URL;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const TAKE_LIVEPOLL_URL = process.env.REACT_APP_TAKE_LIVEPOLL_URL;
export const ADMIN_WS_CONNECTION_URL =
  process.env.REACT_APP_ADMIN_WS_CONNECTION_URL!;
export const TEXT_EDITOR_DEBOUNCE_TIME = +(
  process.env.REACT_APP_TEXT_EDITOR_DEBOUNCE_TIME || 300
);
export const isDevEnv = process.env.NODE_ENV === 'test';
export const LOGOUT_API_URL = process.env.REACT_APP_LOGOUT_API_URL;

export const QP_DOMAIN = process.env.REACT_APP_QP_DOMAIN;

export const QP_LOGOUT_API_URL_PATH = '/a/logout.do';
export const THIRD_PARTY_APP_TYPE_SESSION_STORAGE_KEY = 'thirdPartyAppType';
export const LIVE_POLLS_CHROME_EXTENSION_ID =
  'cbieiahadepfabmenmldnffmjpmbajam';
export const APP_TO_PRESENTER_WINDOW_SESSION_DATA_SENT_MSG =
  'lp-to-presenter-window-session-data-sent';
export const APP_TO_PRESENTER_WINDOW_INITIALIZATION_CHECK_MSG =
  'lp-to-presenter-window-initialization-check';
export const PRESENTER_WINDOW_TO_APP_INITIALIZATION_CHECK_RESPONSE_MSG =
  'presenter-window-to-lp-initialization-check-response';
export const PRESENTER_WINDOW_TO_APP_WAITINGROOM_DATA_RECEIVED_MSG =
  'presenter-window-to-lp-waitingroom-data-received';

export const APP_ORIGIN_KEY_IN_LOCAL_STORAGE = 'appOrigin';
export const TEMPLATE_UID = 'templateUid';

export const SHARABLE_DASHBOARD_URL =
  process.env.REACT_APP_SHARABLE_DASHBOARD_URL;
