import * as React from 'react';
import ErrorDisplay from './ErrorDisplay';

interface State {
  hasError: boolean;
  error: Error | undefined;
}

interface Props {
  componentName: string;
  disableBackLink?: boolean;
  children?: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true, error };
  }

  componentDidCatch(_error: Error, _errorInfo: Object) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  goBack() {
    window.history.go(-1);
    return false;
  }

  render() {
    const { error } = this.state;
    const { children, componentName, disableBackLink } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorDisplay
          componentName={componentName}
          error={error}
          disableBackLink={disableBackLink}
        />
      );
    }

    return children;
  }
}
