function getHMS(d: number): { h: number; m: number; s: number } {
  d = Number(d);

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return { h, m, s };
}

export function secondsToHms(d: number) {
  const { h, m, s } = getHMS(d);

  const hDisplay = h > 0 ? h + ':' : '';
  const mDisplay = m > 0 ? m + '' : '0';

  let sDisplay = '';

  if (h <= 0) {
    let prefix = '';
    if (s < 10) {
      prefix = '0';
    }
    sDisplay = s > 0 ? ':' + (prefix + s.toString()) : '';
  }
  return hDisplay + mDisplay + sDisplay;
}

export function secondsToHmsFormat2(d: number) {
  const { h, m, s } = getHMS(d);

  if (h > 0) {
    let format = h + 'h';
    if (m > 0) {
      format += ` ${m}m`;
    }

    return format;
  }

  if (m > 0) {
    let format = m + 'm';
    if (s > 0) {
      format += ` ${s}s`;
    }

    return format;
  }

  return s + 's';
}

export function dateStringToFormattedDate(dateString: string) {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function timeFormat(time: number | undefined): string {
  if (!time) {
    return '';
  }

  const time_formats: any = [
    [60, 's', 1], // 60
    [120, '1m ago', '1m from now'], // 60*2
    [3600, 'm', 60], // 60*60, 60
    [7200, '1h ago', '1h from now'], // 60*60*2
    [86400, 'h', 3600], // 60*60*24, 60*60
    [172800, 'yesterday', 'tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'last week', 'next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'last month', 'next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'last year', 'next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'last century', 'next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds === 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') {
        return format[list_choice];
      } else {
        let space = '';
        if (i > 5) {
          space = ' ';
        }
        return (
          Math.floor(seconds / format[2]) + space + format[1] + ' ' + token
        );
      }
    }
  return time + '';
}
