import * as React from 'react';
import styles from './Navbar.module.css';

interface Props {
  static?: boolean;
  children: any;
  hasSecondaryNav?: boolean;
}

export const Navbar = (props: Props) => {
  const { hasSecondaryNav } = props;
  return (
    <div
      className={
        hasSecondaryNav
          ? styles.navContainerHasSecondaryNav
          : styles.navContainer
      }
    >
      {props.children}
    </div>
  );
};
