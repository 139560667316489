import * as React from 'react';
import styles from './Input.module.css';

interface Props {
  initialValue?: boolean;
  onChange?: (newValue: boolean) => void;
  placeholder?: string;
  className?: string;
  disable?: boolean;
}

let lastId = 0;
function newId(prefix = 'toggle-switch-') {
  lastId++;
  return `${prefix}${lastId}`;
}

export const ToggleSwitch = ({
  initialValue,
  onChange,
  placeholder,
  className,
  disable,
}: Props) => {
  const [inputValue, setInputValue] = React.useState<boolean>(
    initialValue ? true : false,
  );

  React.useEffect(() => {
    initialValue ? setInputValue(true) : setInputValue(false);
  }, [initialValue]);

  const _id = newId();

  const handleUpdate = (e: any) => {
    setInputValue(e.target.checked);
    onChange && onChange(e.target.checked);
  };

  return (
    <span className={className}>
      <input
        id={_id}
        type="checkbox"
        className={styles.checkbox}
        checked={inputValue}
        onChange={handleUpdate}
        disabled={!!disable}
      />
      <label htmlFor={_id} className={styles.switch}>
        <span>{placeholder}</span>
      </label>
    </span>
  );
};
