import { Route, Routes } from 'react-router-dom';
import { MEETING_APPS_URLS } from 'src/components/navbar/app.urls';
import { ZoomApp } from './zoom-app/ZoomApp';

export const MeetingApps = () => {
  return (
    <Routes>
      <Route path={MEETING_APPS_URLS.ZOOM_APP_HOME} element={<ZoomApp />} />
    </Routes>
  );
};
