import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

export interface SecondaryNavLink {
  active: boolean;
  url: string;
  text: string;
  imageUrl: string;
}

interface Props {
  secondaryNavLinks: SecondaryNavLink[];
  children?: React.ReactNode;
}

export const SecondaryNavbar = (props: Props) => {
  const { secondaryNavLinks } = props;
  return (
    <div className={styles.secondaryNavbar}>
      {secondaryNavLinks.map((link, i) => (
        <Link
          to={link.url}
          key={i}
          className={
            link.active
              ? styles.secondaryNavbarItemActive
              : styles.secondaryNavbarItem
          }
        >
          <img src={link.imageUrl} alt={link.text} width={100} height={25} />
          <span>{link.text}</span>
        </Link>
      ))}
      <div className={styles.secondaryNavbarRight}>{props.children}</div>
    </div>
  );
};
