import * as React from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LpFooter.module.css';
import { getRandomInt } from '../../utils/number.util';
import { TipType } from '../../enums/tip-type.enum';
import { useTranslation } from 'react-i18next';
import { useIsPresenterNotesWindowUrlActive } from '@livepolls/edit-app/src/utils/hooks.util';
import { LpPopup } from '../popup/LpPopup';

interface Tip {
  type: TipType;
  message: string;
}
interface Props {
  text: {
    tips: Tip[];
    locations: string[];
    with: string;
    from: string;
  };
  submitFeedback?(data: { comment: string; rating: number }): void;
}

const getRandomLocation = (locations: string[]) => {
  const locationIndex = getRandomInt(0, locations.length - 1);
  if (locationIndex < 0 || locationIndex >= locations.length) {
    return locations[0];
  }

  return locations[locationIndex];
};

const getTip = (tipIndex: number, locations: string[], tips: Tip[]) => {
  if (tipIndex < 0 || tipIndex >= locations.length) {
    return tips[0];
  }

  return tips[tipIndex];
};

export const LpFooter = ({ submitFeedback, text }: Props) => {
  const [isShowLocation, setShowLocation] = React.useState<boolean>(true);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [location] = React.useState<string>(getRandomLocation(text.locations));
  const [tipIndex, setTipIndex] = React.useState<number>(0);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const [, forceUpdate] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const width = window.innerWidth;
  const isSmallScreen = width < 1000;

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      setTipIndex(getRandomInt(0, text.tips.length - 1));
    }, 60_000);
  };

  const resetTimer = () => {
    timerRef.current && clearTimeout(timerRef.current);
    startTimer();
  };

  React.useEffect(() => {
    const callback = () => {
      console.log('storage event called');
      forceUpdate(oldValue => !oldValue);
    };

    window.addEventListener('storage', callback);

    return () => {
      window.removeEventListener('storage', callback);
    };
  });

  React.useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    startTimer();

    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  });

  const handleToggleShowLocation = () => {
    if (isSmallScreen) {
      return;
    }
    setShowLocation(!isShowLocation);
    setTipIndex(getRandomInt(0, text.tips.length - 1));
    resetTimer();
  };

  const openFeedbackModal = () => {
    setOpenModal(true);
  };
  const closePopup = () => {
    setOpenModal(false);
  };
  const tip = getTip(tipIndex, text.locations, text.tips);

  const presenterNotesWindowUrl = useIsPresenterNotesWindowUrlActive();
  if (presenterNotesWindowUrl) {
    return null;
  }

  if (openModal && submitFeedback) {
    return <LpPopup closePopup={closePopup} submitFeedback={submitFeedback} />;
  }

  return (
    <div className={styles.footer}>
      <div className={styles.leftSide}>
        <div className={styles.container}>
          <span>{t('poweredBy')}</span>
          <span className={styles.companyName}> QuestionPro</span>
        </div>
      </div>
      {submitFeedback && (
        <div className={styles.feedbackContainer} onClick={openFeedbackModal}>
          <span>{t('rateUs')}</span>
        </div>
      )}
      <div
        className={styles.tip}
        onMouseEnter={handleToggleShowLocation}
        onMouseLeave={handleToggleShowLocation}
      >
        {isShowLocation && (
          <span>
            {text.with} <FontAwesomeIcon icon={faHeart} /> {text.from}{' '}
            <span>{location}</span>
          </span>
        )}
        {!isShowLocation && (
          <>
            <strong>{tip.type}</strong> {tip.message}
          </>
        )}
      </div>
    </div>
  );
};
