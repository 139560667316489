import {
  faChartLine,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateRow } from 'src/components/template-row/TemplateRow';
import { useAddLivePollMutation } from 'src/hooks/store-hooks';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import ErrorDisplay from 'src/wick-ui/error/ErrorDisplay';
import Modal from 'src/wick-ui/modal/Modal';
import { OptionCard } from 'src/wick-ui/optioncard/OptionCard';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import styles from './add-livepoll-modal.module.css';
import { useTranslation } from 'react-i18next';
import { URLS } from 'src/components/navbar/app.urls';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import { mapLivePollToSlideSelector } from 'src/stores/livePoll-store.selectors';
import { ILivePollWithStats } from 'src/models/livepoll-total-respondents-and-sessions.interface';

interface Props {
  onCloseModal: () => void;
  forSlides?: boolean;
  hideTemplates?: boolean;
}

const NUM_TEMPLATES_TO_SHOW = 3;

export const AddLivePollModal = ({
  onCloseModal,
  forSlides,
  hideTemplates,
}: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const addLivePollMutation = useAddLivePollMutation();
  const mapLivePollToSlide = useLivePollEditorStore(mapLivePollToSlideSelector);

  const handleAddLivePoll = async (type: LivePollType) => {
    setLoading(true);
    try {
      const livePoll = await addLivePollMutation.mutateAsync({
        type,
      });

      if (forSlides) {
        const navigateToLivePollEditorForSlides = () => {
          navigate(
            `/${URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL.replace(
              ':livePollId',
              `${livePoll.id}`,
            )}`,
          );
        };

        await mapLivePollToSlide(
          livePoll as ILivePollWithStats,
          navigateToLivePollEditorForSlides,
        );
        return;
      }

      navigate(`/livepolls/${livePoll.id}`);
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <Modal
      title={t('newPollModalTitle')}
      closePopup={onCloseModal}
      popupWidth={900}
    >
      <ErrorDisplay
        customHeader="Oops! Error occurred creating LivePoll"
        error={error}
        componentName="AddLivePollModal"
        disableBackLink={true}
      >
        <div className={styles.addLivePollModalContainer}>
          {loading && <LpSpinner />}
          {!loading && (
            <>
              <div>
                <OptionCard
                  text={t('poll')}
                  icon={faChartLine}
                  onClick={() => handleAddLivePoll(LivePollType.POLL)}
                >
                  {t('pollDesc')}
                </OptionCard>

                <OptionCard
                  text={t('quiz')}
                  icon={faGraduationCap}
                  onClick={() => handleAddLivePoll(LivePollType.QUIZ)}
                >
                  {t('quizDesc')}
                </OptionCard>
              </div>

              {!hideTemplates && (
                <div className={styles.templateRow}>
                  <TemplateRow
                    hideShowAndHideTemplatesIcon={true}
                    customClass={styles.gridItem}
                    numTemplatesToShow={NUM_TEMPLATES_TO_SHOW}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </ErrorDisplay>
    </Modal>
  );
};
