import * as React from 'react';
import { useLocation, useMatch } from 'react-router-dom';

function useSafeDispatch(dispatch: React.Dispatch<any>) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return React.useCallback(
    // @ts-ignore
    (...args: any[]) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  );
}

interface InitialState<T> {
  status: 'idle' | 'pending' | 'resolved' | 'rejected';
  error: null | Error;
  data: T | null;
}

const defaultInitialState = { status: 'idle', data: null, error: null };

function useAsync<Data>(initialState?: InitialState<Data>) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s: any, a: any) => ({ ...s, ...a }),
    initialStateRef.current,
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback(
    (data: any) => safeSetState({ data, status: 'resolved' }),
    [safeSetState],
  );
  const setError = React.useCallback(
    (error: Error) => safeSetState({ error, status: 'rejected' }),
    [safeSetState],
  );
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  );

  const run = React.useCallback(
    (promise: any) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (data: Data) => {
          setData(data);
          return data;
        },
        (error: Error) => {
          setError(error);
          return Promise.reject(error);
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

const useLivePollIdFromUrl = () => {
  const match = useMatch('/livepolls/:livePollId/*');

  if (match != null) {
    const activeLivePollId = +(match?.params.livePollId || '');
    if (Number.isNaN(activeLivePollId) || activeLivePollId <= 0) {
      throw new Error(`Invalid livePollId in url: ${activeLivePollId}`);
    }

    return activeLivePollId;
  }

  return 0;
};

const useOptLivePollIdFromUrl = () => {
  const match = useMatch('/livepolls/:livePollId/*');
  const activeLivePollId = +(match?.params.livePollId || '');

  if (Number.isNaN(activeLivePollId) || activeLivePollId <= 0) {
    return '';
  }

  return activeLivePollId;
};

const useIsGoogleSlidesUrlActive = () => {
  const location = useLocation();
  return location.pathname.includes('google-slides');
};

function useGetLatest<T>(obj: T) {
  const ref = React.useRef<T>(obj);
  ref.current = obj;

  return React.useCallback(() => ref.current, []);
}

export function useAsyncDebounce(defaultFn: Function, defaultWait = 0) {
  const debounceRef = React.useRef<{
    promise?: Promise<any>;
    resolve?: Function;
    reject?: Function;
    timeout?: NodeJS.Timeout;
  }>({});

  const getDefaultFn = useGetLatest<Function>(defaultFn);
  const getDefaultWait = useGetLatest<number>(defaultWait);

  return React.useCallback(
    async (...args: any) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve!(await getDefaultFn()(...args));
        } catch (err) {
          debounceRef.current.reject!(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait],
  );
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

const useIsPresenterNotesWindowUrlActive = () => {
  const location = useLocation();
  return location.pathname.includes('/run/window');
};

export {
  useAsync,
  useLivePollIdFromUrl,
  useOptLivePollIdFromUrl,
  useIsPresenterNotesWindowUrlActive,
  useIsGoogleSlidesUrlActive,
};
