import * as React from 'react';
import { IUser } from '../models/user.interface';
import {
  API_URL,
  APP_ORIGIN_KEY_IN_LOCAL_STORAGE,
  TEMPLATE_UID,
} from '../constants/app.constants';
import { redirectTo } from '../utils/redirect.util';
import { getApi } from 'src/utils/api.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { AxiosResponse } from 'axios';
import { useThirdPartyAppIntegration } from './ThirdPartyAppsContext';
import { ThirdPartyAppType } from 'src/models/third-party-app-type.enum';
import {
  expandZoomApp,
  openUrlInBrowserFromZoomApp,
} from 'src/utils/zoom-sdk.utils';
import { useTranslation } from 'react-i18next';

const OAUTH_URL = `${API_URL}/auth/oauth`;
const PRESENTER_LOGIN_IN_ZOOM_URL = `${API_URL}/auth/zoom/admin/login`;

function getOauthUrl(appOrigin: string) {
  return `${OAUTH_URL}?appOrigin=${appOrigin}`;
}

function callUserApi(): Promise<IUser> {
  return getApi(`/auth/user`);
}

function callLogoutApi(): Promise<AxiosResponse> {
  return getApi(`/auth/logout`);
}

function callSetUserSessionApi(): Promise<AxiosResponse> {
  return getApi(`/auth/zoom/set-qp-user-session`);
}

function redirectToQpForLogin() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const appOrigin =
    params.get('appOrigin') ||
    localStorage.getItem(APP_ORIGIN_KEY_IN_LOCAL_STORAGE);

  //set templateUid to local-storage
  let templateUid = params.get('livePollsTemplateUID');
  if (templateUid) {
    localStorage.setItem(TEMPLATE_UID, templateUid);
  }

  if (appOrigin != null) {
    localStorage.setItem(APP_ORIGIN_KEY_IN_LOCAL_STORAGE, appOrigin);
  }

  redirectTo(getOauthUrl(appOrigin || ''));
}
interface IAuthContext {
  user?: IUser;
  sessionExpiredReLogin?: Function;
  login: Function;
}

const AuthContext = React.createContext<IAuthContext>({ login: () => {} });
AuthContext.displayName = 'AuthContext';

function AuthProvider(props: any) {
  const { i18n } = useTranslation();
  const { thirdPartyAppType, zoomConfigResponse } =
    useThirdPartyAppIntegration();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<IUser | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const openLoginUrlInBrowser = React.useCallback(async () => {
    const isSuccess = await openUrlInBrowserFromZoomApp(
      PRESENTER_LOGIN_IN_ZOOM_URL,
    );
    if (!isSuccess) {
      setError('Error in ZoomSDK. Cannot open url to login.');
    }
  }, []);

  const callUserApiAndSetData = React.useCallback(async () => {
    setIsLoading(true);
    const data = await callUserApi();
    setUser(data);
    setIsLoading(false);
  }, []);

  const setUserSessionForZoomApp = React.useCallback(async () => {
    try {
      await callSetUserSessionApi();
      await callUserApiAndSetData();
    } catch (err) {
      await openLoginUrlInBrowser();
    }
  }, [openLoginUrlInBrowser, callUserApiAndSetData]);

  const loginByAppType = React.useCallback(async () => {
    if (thirdPartyAppType === ThirdPartyAppType.ZOOM_APP) {
      setIsLoading(true);
      openLoginUrlInBrowser();
    } else {
      redirectToQpForLogin();
    }
  }, [thirdPartyAppType, openLoginUrlInBrowser]);

  const updateAppLang = React.useCallback(
    async (languageCode: string) => {
      try {
        await i18n.changeLanguage(languageCode);
      } catch (err: any) {
        setError(err?.message);
      }
    },
    [i18n],
  );

  React.useEffect(() => {
    (async () => {
      if (
        thirdPartyAppType === ThirdPartyAppType.ZOOM_APP &&
        !!zoomConfigResponse
      ) {
        const isSuccess = await expandZoomApp(
          zoomConfigResponse?.runningContext,
        );
        if (!isSuccess) {
          setError('Error in ZoomSDK. Cannot expand app.');
          return;
        }
        try {
          await callUserApiAndSetData();
        } catch (err) {
          await setUserSessionForZoomApp();
        }
      } else {
        try {
          await callUserApiAndSetData();
        } catch (err) {
          redirectToQpForLogin();
        }
      }
    })();
  }, [
    thirdPartyAppType,
    zoomConfigResponse,
    callUserApiAndSetData,
    setUserSessionForZoomApp,
  ]);

  React.useEffect(() => {
    if (user) {
      updateAppLang(user.languageCode);
    }
  }, [user, updateAppLang]);

  const sessionExpiredReLogin = React.useCallback(async () => {
    callLogoutApi();
    setUser(null);
    await loginByAppType();
  }, [loginByAppType]);

  const login = React.useCallback(async () => {
    await loginByAppType();
  }, [loginByAppType]);

  const value = React.useMemo(
    () => ({
      user,
      sessionExpiredReLogin,
      login,
    }),
    [sessionExpiredReLogin, user, login],
  );

  if (error) {
    return <div>Oops an error occurred ({error}).</div>;
  }

  if (isLoading) {
    return <LpSpinner message="Loading..." />;
  }

  if (!isLoading) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status`);
}

function useAuth(): {
  user: IUser;
  sessionExpiredReLogin: Function;
  login: Function;
} {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  const { user, sessionExpiredReLogin, login } = context;
  if (!user || !sessionExpiredReLogin) {
    throw new Error(
      'User not yet fetched. Consider using "useOptAuth" ' +
        'if you expect user to be not initialzed.',
    );
  }

  return { user, sessionExpiredReLogin, login };
}

function useOptAuth(): IAuthContext {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

function useSessionExpiredReLogin() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      `useSessionExpiredReLogin must be used within a AuthProvider`,
    );
  }

  return context.sessionExpiredReLogin;
}

function useLogin() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useLogin must be used within a AuthProvider`);
  }

  return context.login;
}

export {
  AuthProvider,
  useAuth,
  useOptAuth,
  useSessionExpiredReLogin,
  useLogin,
};
