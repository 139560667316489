interface Props {
  width: number;
  height: number;
}

export const CloseIcon = ({ width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.09091L7.09091 6L12 10.9091L10.9091 12L5.99945 7.09036L1.09091 12L0 10.9091L4.90855 5.99945L0 1.09091L1.09091 0L6 4.90909L10.9091 0L12 1.09091Z"
        fill="currentColor"
      />
    </svg>
  );
};
