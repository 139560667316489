import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { addLivePollFromTemplateUid } from 'src/hooks/store-hooks';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';

export const CreateQuizFromTemplate = () => {
  const { state } = useLocation();
  const templateUid = state as string;
  const [livePollId, setLivePollId] = useState<number | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const createLivePoll = useCallback(async () => {
    try {
      const livePoll = await addLivePollFromTemplateUid(templateUid);
      if (livePoll) {
        setLivePollId(livePoll.id);
      }
    } catch (err) {
      setIsError(true);
    } finally {
      localStorage.removeItem('templateUid');
    }
  }, [templateUid]);

  useEffect(() => {
    createLivePoll();
  }, [createLivePoll]);

  if (isError) {
    throw new Error('Error while creating LivePoll through template');
  }

  if (livePollId) {
    return <Navigate to={`/livepolls/${livePollId}`} replace />;
  }

  return <LpSpinner message="Creating LivePoll from template..." />;
};
