export const CopyPermanentRoomLinkSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.99995L11 7.99995H6V2.99995ZM3.28194 2.99995H5V8.99995H11V14.718C11 15.1638 10.9536 15.3254 10.8664 15.4884C10.7793 15.6513 10.6514 15.7792 10.4884 15.8664C10.3255 15.9535 10.1638 15.9999 9.71806 15.9999H3.28194C2.83618 15.9999 2.67454 15.9535 2.51158 15.8664C2.34861 15.7792 2.22072 15.6513 2.13357 15.4884C2.04641 15.3254 2 15.1638 2 14.718V4.28189C2 3.83613 2.04641 3.67449 2.13357 3.51153C2.22072 3.34856 2.34861 3.22067 2.51158 3.13352C2.67454 3.04636 2.83618 2.99995 3.28194 2.99995ZM5 0H9L14 5V12C14 12.5523 13.5523 13 13 13H12V7L7 2H4V1C4 0.447715 4.44772 0 5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
