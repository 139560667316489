import React from 'react';
import styles from './LpToolTip.module.css';
interface Props {
  children: any;
  textOnHover?: string;
  showBetaFeatureMessage?: boolean;
}
export const LpToolTip = ({
  children,
  textOnHover,
  showBetaFeatureMessage,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      <div className={styles.toolTipText} data-testid="textToDisplay">
        {showBetaFeatureMessage ? (
          <div>
            <div>
              This is an <i>experimental feature.</i>
            </div>
            <div>
              An experimental feature is one where the design is not production
              ready. But the feature is available for users to test and provide
              feedback.
            </div>
          </div>
        ) : (
          textOnHover
        )}
      </div>
    </div>
  );
};
