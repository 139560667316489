export const POLL_TEMPLATE_CATEGORIES = [
  {
    name: 'Classroom',
    colors: { primary: '#4d8ac6', secondary: '#639ED8' },
    displayText: 'classRoom',
  },
  {
    name: 'Meeting',
    colors: { primary: '#60ab9e', secondary: '#56C3B1' },
    displayText: 'meeting',
  },
  {
    name: 'Workplace',
    colors: { primary: '#da2222', secondary: '#EC3737' },
    displayText: 'workplace',
  },
];
