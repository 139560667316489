import { addTheme, deleteTheme, updateTheme } from 'src/hooks/store-hooks';
import { ICustomThemes } from 'src/models/custom-themes.interface';
import { ThemeFont } from '@livepolls/ui-components/src/enums/theme-font.enum';
import { ThemeType } from '@livepolls/ui-components/src/enums/theme-type.enum';
import {
  ITheme,
  ThemeSettingFieldDto,
} from '@livepolls/ui-components/src/interfaces/theme.interface';
import create from 'zustand';
import {
  findCustomizedLivePollTheme,
  findThemeById,
  findThemeIndex,
  getStandardThemeName,
  isStandardThemeSetting,
} from '../utils/theme.utils';
import { BackgroundOverlayEnum } from 'src/models/background-overlay.enum';
export interface ILivePollDesignStore {
  id?: number;
  name?: string;
  logo?: string;
  type?: ThemeType;
  fontFamily?: ThemeFont;
  coverImage?: string;
  coverColor?: string;
  mainColor?: string;
  buttonColor?: string;
  titleColor?: string;
  answerColor?: string;
  sessionNameColor?: string;
  bgOverlay?: BackgroundOverlayEnum;
  loading: boolean;
  error: Error | null;
  selectedThemeId?: number;
  themes: ITheme[];
  isPropertyChanged?: boolean;
  setTheme: (theme: ICustomThemes) => void;
  resetStore: () => void;
  setLogo: (imageUrl: string) => void;
  setCoverImage: (imageUrl: string) => void;
  setBackgroundOverlay: (bgOverlay: BackgroundOverlayEnum) => void;
  setFontFamily: (fontFamily: ThemeFont) => void;
  setCoverColor: (coverColor: string) => void;
  setMainColor: (mainColor: string) => void;
  setButtonColor: (buttonColor: string) => void;
  setTitleColor: (titleColor: string) => void;
  setAnswerColor: (answerColor: string) => void;
  setSessionNameColor: (sessionNameColor: string) => void;
  addNewThemeMutation: (livePollId: number, themeName: string) => void;
  updateThemeMutation: (livePollId: number) => void;
  deleteThemesMutation: (themeIds: number[], livePollId: number) => void;
  removeDeletedThemes: (themeIds: number[], theme: ITheme[]) => void;
  setCurrentThemeProps: (theme: ITheme) => void;
  updateIsPropertyChanged: (newValue: boolean) => void;
  updateLivePollCustomizedTheme: (themeSetting: ThemeSettingFieldDto) => void;
  updateNameIfStandardTheme: (theme: ITheme) => void;
  updateNameIfPropertyChanged: () => void;
}

export const useLivePollDesignStore = create<ILivePollDesignStore>(
  (set, get) => ({
    themes: [],
    error: null,
    loading: false,
    isPropertyChanged: false,
    setTheme: (theme: ICustomThemes) => {
      const { selectedThemeId, themes } = theme;
      set({ themes: JSON.parse(JSON.stringify(themes)) });

      if (selectedThemeId) {
        const theme = findThemeById(selectedThemeId, themes);
        if (theme) {
          set({ selectedThemeId });
          get().setCurrentThemeProps(theme);
          return;
        }
      }
      try {
        const customizedLivePllTheme = findCustomizedLivePollTheme(themes);
        get().setCurrentThemeProps(customizedLivePllTheme);
        get().updateNameIfStandardTheme(customizedLivePllTheme);
      } catch (err: any) {
        set({ error: err });
      }
    },
    resetStore: () =>
      set(state => {
        state.selectedThemeId = undefined;
        state.themes = [];
        state.id = undefined;
        state.name = undefined;
        state.logo = undefined;
        state.type = undefined;
        state.coverImage = undefined;
        state.coverColor = undefined;
        state.mainColor = undefined;
        state.buttonColor = undefined;
        state.fontFamily = undefined;
        state.titleColor = undefined;
        state.answerColor = undefined;
        state.bgOverlay = undefined;
        state.sessionNameColor = undefined;
        state.error = null;
        state.loading = false;
        state.isPropertyChanged = false;
      }),
    setCurrentThemeProps: (theme: ITheme) => {
      set(state => {
        state.id = theme.id;
        state.name = theme.name;
        state.logo = theme.setting.logo;
        state.coverImage = theme.setting.coverImage;
        state.type = theme.type;
        state.coverColor = theme.setting.color.cover;
        state.mainColor = theme.setting.color.main;
        state.buttonColor = theme.setting.color.button;
        state.fontFamily = theme.setting.font;
        state.titleColor = theme.setting.color.title;
        state.answerColor = theme.setting.color.answer;
        state.sessionNameColor = theme.setting.color.sessionName;
        state.bgOverlay = theme.setting.bgOverlay;
      });
    },
    setLogo: (imageUrl: string) => {
      set({ logo: imageUrl, isPropertyChanged: true });
      get().updateNameIfPropertyChanged();
    },
    setCoverImage: (imageUrl: string) => {
      set({ coverImage: imageUrl, isPropertyChanged: true });
      get().updateNameIfPropertyChanged();
    },
    setBackgroundOverlay: (bgOverlay: BackgroundOverlayEnum) => {
      set(state => {
        state.bgOverlay = bgOverlay;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setFontFamily: (fontFamily: ThemeFont) => {
      set(state => {
        state.fontFamily = fontFamily;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setCoverColor: (coverColor: string) => {
      set(state => {
        state.coverColor = coverColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setMainColor: (mainColor: string) => {
      set(state => {
        state.mainColor = mainColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setButtonColor: (buttonColor: string) => {
      set(state => {
        state.buttonColor = buttonColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setTitleColor: (titleColor: string) => {
      set(state => {
        state.titleColor = titleColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setAnswerColor: (answerColor: string) => {
      set(state => {
        state.answerColor = answerColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    setSessionNameColor: (sessionNameColor: string) => {
      set(state => {
        state.sessionNameColor = sessionNameColor;
        state.isPropertyChanged = true;
      });
      get().updateNameIfPropertyChanged();
    },
    addNewThemeMutation: async (livePollId: number, themeName: string) => {
      const addThemeData = {
        name: themeName,
        type: ThemeType.CUSTOMIZED_USER_NAMED,
        logo: get().logo!,
        coverImage: get().coverImage!,
        font: get().fontFamily!,
        color: {
          cover: get().coverColor!,
          main: get().mainColor!,
          button: get().buttonColor!,
          title: get().titleColor!,
          answer: get().answerColor!,
          sessionName: get().sessionNameColor!,
        },
      };
      set({ loading: true });
      try {
        const theme = await addTheme(addThemeData, livePollId);
        set(state => {
          state.themes.push({ ...theme });
          state.selectedThemeId = theme.id;
          state.isPropertyChanged = false;
        });
        get().setCurrentThemeProps(theme);
      } catch (err: any) {
        set({ error: err });
      }
      set({ loading: false });
    },

    deleteThemesMutation: async (themeIds: number[], livePollId: number) => {
      try {
        await deleteTheme(livePollId, themeIds);

        set(state => {
          const themes = state.themes;
          const filteredThemes = get().removeDeletedThemes(themeIds, themes);
          state.themes = JSON.parse(JSON.stringify(filteredThemes));

          const theme = themes.filter(
            theme => theme.name === 'Default Theme',
          )[0];

          if (theme) {
            get().setCurrentThemeProps(theme);
          } else {
            get().resetStore();
          }
        });
      } catch (err: any) {
        set({ error: err });
      }
    },

    updateThemeMutation: async (livePollId: number) => {
      const id = get().id!;
      const cover = get().coverColor!;
      const main = get().mainColor!;
      const button = get().buttonColor!;
      const title = get().titleColor!;
      const answer = get().answerColor!;
      const sessionName = get().sessionNameColor!;

      const updateThemeData = {
        logo: get().logo!,
        coverImage: get().coverImage!,
        font: get().fontFamily!,
        bgOverlay: get().bgOverlay!,
        color: {
          cover,
          main,
          button,
          title,
          answer,
          sessionName,
        },
      };
      try {
        set({ loading: true });

        await updateTheme(updateThemeData, livePollId, id);
        const customThemes = get().themes!;
        const themeToUpdateIndex = findThemeIndex(id, customThemes);
        const themeToUpdate = {
          ...customThemes[themeToUpdateIndex],
          setting: { ...updateThemeData },
        };
        const updatedCustomThemes = [
          ...customThemes.slice(0, themeToUpdateIndex),
          themeToUpdate,
          ...customThemes.slice(themeToUpdateIndex + 1),
        ];

        set(state => {
          state.themes = updatedCustomThemes;
          state.selectedThemeId = id;
          state.isPropertyChanged = false;
        });
      } catch (err: any) {
        set({ error: err });
      } finally {
        set({ loading: false });
      }
    },

    updateIsPropertyChanged: (newValue: boolean) => {
      set({ isPropertyChanged: newValue });
    },

    removeDeletedThemes: (themeIds: number[], themes: ITheme[]) => {
      let filteredArray = themes.filter(theme => {
        return (
          themeIds.filter(function (themeId) {
            return theme.id === themeId;
          }).length === 0
        );
      });
      return filteredArray;
    },

    updateLivePollCustomizedTheme: (themeSetting: ThemeSettingFieldDto) => {
      const themes = get().themes;
      const customizedLivePollThemeIndex = themes.findIndex(
        theme => theme.type === ThemeType.CUSTOMIZED_LIVEPOLL,
      )!;
      const updatedTheme = {
        ...themes[customizedLivePollThemeIndex],
        setting: { ...themeSetting },
      };
      const updatedCustomThemes = [
        ...themes.slice(0, customizedLivePollThemeIndex),
        updatedTheme,
        ...themes.slice(customizedLivePollThemeIndex + 1),
      ];

      set({ themes: updatedCustomThemes });
    },

    updateNameIfStandardTheme: (theme: ITheme) => {
      if (
        theme.type === ThemeType.CUSTOMIZED_LIVEPOLL &&
        isStandardThemeSetting(theme.setting)
      ) {
        set({ name: getStandardThemeName(theme.setting.color.button) });
      }
    },

    updateNameIfPropertyChanged: () => {
      const themeType = get().type!;
      if (themeType === ThemeType.CUSTOMIZED_LIVEPOLL) {
        set({ name: 'Default Theme' });
      }
    },
  }),
);
