import React from 'react';
import styles from './Card.module.css';
import CardTitle from './CardTitle';
import CardSubtitle from './CardSubtitle';
import CardFooter from './CardFooter';
import CardBody from './CardBody';
import CardDrawer from './CardDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CardContext } from './CardContext';
import HoverCardBody from './HoverCardBody';

interface Props {
  onClick?: () => void;
  selectable?: boolean;
  cornerIcon?: IconDefinition;
  hoverValue?: string;
  cornerRounded?: boolean;
  children?: React.ReactNode;
}

interface State {
  hover: boolean;
  hoverValue: string;
}

export default class Card extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    const { hoverValue } = this.props;

    this.state = {
      hover: false,
      hoverValue: hoverValue ? hoverValue : '',
    };
  }

  public render() {
    const { onClick, selectable, children, cornerIcon, cornerRounded } =
      this.props;
    this.validate();

    const borderStyle = {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    };

    return (
      <CardContext.Provider value={this.state}>
        <div
          className={styles.card + ' ' + (selectable ? styles.selectable : '')}
          style={cornerRounded ? borderStyle : {}}
          onClick={onClick}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}
        >
          {cornerIcon && (
            <span className={styles.cornerIcon}>
              <FontAwesomeIcon icon={cornerIcon} size="2x" />
            </span>
          )}

          <div className={styles.container}>{children}</div>
        </div>
      </CardContext.Provider>
    );
  }

  private validate() {
    React.Children.forEach(this.props.children, child => {
      if (React.isValidElement(child) && !this.isValidType(child)) {
        const validTypes =
          'CardTitle|CardSubtitle|CardBody|CardFooter|CardDrawer';
        throw new Error(
          `Invalid child: ${child.type}. Card only accepts children of type ${validTypes}.`,
        );
      }
    });
  }

  private handleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  private isValidType(child: any) {
    const { type } = child;
    return (
      type.name === CardTitle.name ||
      type.name === CardSubtitle.name ||
      type.name === CardBody.name ||
      type.name === CardFooter.name ||
      type.name === CardDrawer.name ||
      type.name === HoverCardBody.name
    );
  }
}
