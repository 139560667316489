import React, { useEffect, useState } from 'react';
import { URLS } from './app.urls';
import { Navbar } from '../../wick-ui/navbar/Navbar';
import { Left } from '../../wick-ui/navbar/Left';
import { NavItem } from '../../wick-ui/navbar/NavItem';
import { Right } from '../../wick-ui/navbar/Right';
import { ApiStateDisplay } from '../api-state-display/api-state-display';
import {
  useIsGoogleSlidesUrlActive,
  useOptLivePollIdFromUrl,
} from 'src/utils/hooks.util';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { matchUrls } from 'src/utils/url.util';

interface NavItemDetails {
  text: string;
  url: string;
  childRoutes?: string[];
}

interface IProps {
  children?: React.ReactNode;
}

export const PrimaryNavbar = ({ children }: IProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string>(
    location.pathname,
  );
  const isLivePollEditorForSlideActive = useIsGoogleSlidesUrlActive();
  const activeLivePollId = useOptLivePollIdFromUrl();

  useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
    }
  }, [currentLocation, location]);

  const navItems = [
    {
      text: `${t('my')} ${t('livePolls')}`,
      url: getMyLivePollsUrl(isLivePollEditorForSlideActive),
    },
    {
      text: t('edit'),
      url: getLivePollEditorUrl(isLivePollEditorForSlideActive)!.replace(
        ':livePollId',
        `${activeLivePollId}`,
      ),

      childRoutes: [
        URLS.EDIT_REGULAR_LIVE_POLL_URL.replace(
          ':livePollId',
          `${activeLivePollId}`,
        ),
        URLS.EDIT_PROFILE_LIVE_POLL_URL.replace(
          ':livePollId',
          `${activeLivePollId}`,
        ),
        URLS.SETTINGS_LIVE_POLL_URL.replace(
          ':livePollId',
          `${activeLivePollId}`,
        ),
        URLS.DESIGN_LIVE_POLL_URL.replace(':livePollId', `${activeLivePollId}`),
        URLS.LIVE_POLL_DESIGN_URL_FOR_SLIDES.replace(
          ':livePollId',
          `${activeLivePollId}`,
        ),
      ],
    },
  ];

  if (!isLivePollEditorForSlideActive) {
    navItems.push({
      text: t('sessionAndAnalytics'),
      url: URLS.SESSIONS_SESSION_LIST.replace(
        ':livePollId',
        `${activeLivePollId}`,
      ),
      childRoutes: [
        URLS.LIVE_POLL_SESSION_DASHBOARD.replace(
          ':livePollId',
          `${activeLivePollId}`,
        ),
      ],
    });
  }

  const hideNavbarForUrls = [
    URLS.LIVE_POLL_LIST_URL,
    URLS.LIVE_POLL_LIST_FOR_SLIDES_URL,
  ];

  if (
    !isAnyRouteActive(currentLocation, navItems) ||
    hideNavbar(currentLocation, hideNavbarForUrls)
  ) {
    return null;
  }

  return (
    <Navbar>
      <Left>
        {navItems
          .filter(n => canDisplayNavItem(currentLocation, n, navItems))
          .map((n, index) => (
            <NavItem
              key={index}
              text={n.text}
              active={isRouteActive(currentLocation, n)}
              url={n.url}
            />
          ))}
      </Left>
      <Right>
        <ApiStateDisplay />
      </Right>
    </Navbar>
  );
};

const isRouteActive = (
  currentLocation: string,
  navItem: NavItemDetails,
): boolean => {
  const isParentRouteActive = matchUrls(currentLocation, navItem.url);

  // If parent route does not match, check if any child route is matching
  if (
    !isParentRouteActive &&
    typeof navItem.childRoutes !== 'undefined' &&
    navItem.childRoutes.length > 0
  ) {
    return (
      navItem.childRoutes.filter(childUrl =>
        matchUrls(currentLocation, childUrl),
      ).length > 0
    );
  }

  return isParentRouteActive;
};

const isAnyRouteActive = (
  currentLocation: string,
  navItems: NavItemDetails[],
): boolean => {
  return navItems.find(n => isRouteActive(currentLocation, n)) != null;
};

const canDisplayNavItem = (
  currentLocation: string,
  navItem: NavItemDetails,
  navItems: NavItemDetails[],
): boolean => {
  const listNavItem = navItems.find(n => n.url === URLS.LIVE_POLL_LIST_URL);
  if (
    listNavItem != null &&
    navItem.url !== URLS.LIVE_POLL_LIST_URL &&
    isRouteActive(currentLocation, listNavItem)
  ) {
    return false;
  }

  return true;
};

const hideNavbar = (currentLocation: string, hideNavbar: string[]): boolean => {
  return hideNavbar.find(n => matchUrls(currentLocation, n)) != null;
};

const getLivePollEditorUrl = (isSlidesUrlActive: boolean) => {
  if (isSlidesUrlActive) {
    return URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL;
  }

  return URLS.EDIT_LIVE_POLL_URL;
};

const getMyLivePollsUrl = (isSlidesUrlActive: boolean) => {
  if (isSlidesUrlActive) {
    return URLS.LIVE_POLL_LIST_FOR_SLIDES_URL;
  }

  return URLS.LIVE_POLL_LIST_URL;
};
