import { ITemplateType } from 'src/models/template.interface';
import { getTemplateCategoriesByTemplateType } from './getTemplateCategories.util';

const defaultTemplateCategoryColor = {
  primary: '#F58300',
  secondary: '#F5A300',
};

export const getTemplateColorByCategory = (
  category: string,
  templateType: ITemplateType,
) => {
  const templateCategories = getTemplateCategoriesByTemplateType(templateType);
  const storedCategory = templateCategories.find(cat => cat.name === category);
  if (storedCategory) {
    return storedCategory.colors;
  }
  return defaultTemplateCategoryColor;
};
