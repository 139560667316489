import * as React from 'react';
import styles from './LivePollList.module.css';
import Card from 'src/wick-ui/card/Card';
import CardBody from 'src/wick-ui/card/CardBody';
import { PrimaryButton } from 'src/components/button/PrimaryButton';
import { AddLivePollModal } from './components/add-livepoll-modal';
import { useLivePolls, useUserSetting } from 'src/hooks/store-hooks';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import livePollsLogoTitle from 'src/images/livepolls-logo-title.svg';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import { LivePollListTopBar } from './components/LivePollListTopBar';
import { LivePollsView } from 'src/models/livepolls-view.enum';
import { Paginate } from 'src/models/paginate.enum';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { useTranslation } from 'react-i18next';
import { GoogleSlidesLivePollListTable } from './components/GoogleSlidesLivePollListTable';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import {
  errorSelector,
  isErrorSelector,
  isLoadingSelector,
  mappedLivePollOnSlidesSelector,
} from 'src/stores/livePoll-store.selectors';

const defaultPagination = (): IPaginateDropdown => {
  return {
    pageIndex: Paginate.PAGE_INDEX,
    perPage: Paginate.PER_PAGE,
  };
};

export const GoogleSlidesLivePollListScreen = () => {
  const { t } = useTranslation();
  const [isAddModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const { data: userSetting, error: userSettingError } = useUserSetting();

  const isLoading = useLivePollEditorStore(isLoadingSelector);
  const isError = useLivePollEditorStore(isErrorSelector);
  const error = useLivePollEditorStore(errorSelector);

  const mappedLivePollFromSlides = useLivePollEditorStore(
    mappedLivePollOnSlidesSelector,
  );

  const [livePollsViewType] = React.useState<LivePollsView>(LivePollsView.LIST);
  const [sortingParams, setSortingParams] =
    React.useState<IFetchLivePollsSortingParams>({});
  const [pager, setPager] = React.useState<IPaginateDropdown>(
    defaultPagination(),
  );

  const [searchText, setSearchText] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSortingParams({
      sortBy: userSetting?.sortBy,
      sortOrder: userSetting?.sortOrder,
    });
  }, [userSetting]);

  const handleToggleAddLivePoll = React.useCallback(() => {
    setAddModalOpen(!isAddModalOpen);
  }, [isAddModalOpen]);

  const handleLivePollListSort = (
    sortingParams: IFetchLivePollsSortingParams,
  ) => {
    setSearchText(sortingParams.title || null);
    pager.pageIndex > 1 && setPager({ pageIndex: 1, perPage: pager.perPage });
    setSortingParams({ ...sortingParams });
  };

  const {
    isError: isLivePollError,
    isLoading: isLivePollsLoading,
    error: livePollsError,
    data: livePollsPaginate,
  } = useLivePolls(sortingParams, livePollsViewType, pager);

  if (userSettingError) throw userSettingError;

  if (isLivePollError) {
    throw livePollsError;
  }

  if (isError) {
    throw error;
  }

  const handlePageChange = (pager: IPaginateDropdown) => {
    setPager({ ...pager });
  };

  const createLivePollCard = (
    <div className={styles.createLivePollCard}>
      <Card selectable={true} onClick={handleToggleAddLivePoll}>
        <CardBody>
          <div className={styles.primaryButtonContainer}>
            <PrimaryButton text={`${t('create')} ${t('newLivePoll')}`} />
          </div>
        </CardBody>
      </Card>
    </div>
  );

  const areLivePollsPresent = !!(livePollsPaginate?.livePolls.length! > 0);

  let content = <LpSpinner message={`${t('loadingLivePolls')}...`} />;
  const livePolls = areLivePollsPresent
    ? [...livePollsPaginate!.livePolls]
    : [];

  if (livePolls.length > 0 && mappedLivePollFromSlides != null) {
    const livePollFoundIndex = livePolls.findIndex(
      livePoll => livePoll.id === mappedLivePollFromSlides.id,
    );

    // mapped LivePoll should always be first the first row
    if (livePollFoundIndex !== -1) {
      const livePollFound = livePolls[livePollFoundIndex];
      livePolls.splice(livePollFoundIndex, 1);
      livePolls.unshift(livePollFound);
    } else {
      livePolls.unshift(mappedLivePollFromSlides);
      livePolls.pop();
    }
  }

  if (isLoading || isLivePollsLoading) {
    content = <LpSpinner message={`${t('loadingLivePolls')}...`} />;
  } else if (areLivePollsPresent) {
    content = (
      <div className={styles.homeScreenContainer}>
        <LivePollListTopBar onAddNewLivePoll={handleToggleAddLivePoll} />
        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <GoogleSlidesLivePollListTable
              sortingParams={sortingParams}
              pager={pager}
              totalItems={livePollsPaginate?.totalItems!}
              livePolls={livePolls}
              onTableHeadClick={handleLivePollListSort}
              onPageChange={handlePageChange}
              searchText={searchText}
              mappedLivePollId={mappedLivePollFromSlides?.id}
            />
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className={styles.emptyState}></div>
        <div className={styles.welcomeContainer}>
          <span className={styles.welcomeMessage}>{t('welcomeTo')}</span>
          <img
            className={styles.welcomeImage}
            src={livePollsLogoTitle}
            height="20px"
            alt="LivePolls home page"
          />
          {createLivePollCard}
        </div>
      </>
    );
  }

  return (
    <>
      {content}
      {isAddModalOpen && (
        <AddLivePollModal
          onCloseModal={handleToggleAddLivePoll}
          forSlides={true}
          hideTemplates={true}
        />
      )}
    </>
  );
};
