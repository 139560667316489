export enum ThemeFont {
  FIRA_SANS = `'Fira Sans', sans-serif`,
  HELVETICA = `Helvetica`,
  TIMES_NEW_ROMAN = `Times New Roman`,
  ARIAL = `Arial`,
  AVANT_GARDE = `Avant Garde`,
  COURIER_NEW = `Courier New Regular`,
  VERDANA = `Verdana Pro`,
  GEORGIA = `Georgia`,
  TAHOMA = `Tahoma`,
}
