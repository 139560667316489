import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { useSessionExpiredReLogin } from '../../contexts/auth.context';

interface Props {
  children: React.ReactNode;
}
export const OtherErrorBoundary = ({ children }: Props) => {
  const sessionExpiredReLogin = useSessionExpiredReLogin();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => {
            const hasLoggedOut =
              sessionExpiredReLogin && (error as any)?.response?.status === 403;
            hasLoggedOut && sessionExpiredReLogin!();

            return (
              <div>
                Oops an error occurred ({error.message}).
                <button onClick={() => resetErrorBoundary()}>Try again</button>
              </div>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
