interface Props {
  width: number;
  height: number;
}

export const TickMarkIcon = ({ width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599075 10.2081C1.39802 9.42167 2.69321 9.42119 3.49275 10.207L6.38426 13.049L16.5079 3.08946C17.307 2.30336 18.6021 2.30354 19.4009 3.08986C20.1997 3.87612 20.1997 5.1509 19.4009 5.93716L6.38426 18.75L0.599075 13.0554C-0.199692 12.2691 -0.199692 10.9944 0.599075 10.2081Z"
        fill="currentColor"
      />
    </svg>
  );
};
