import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TableHead.module.css';
interface Props {
  text: string;
  onClick?: Function;
  className?: string;
  icon?: IconProp;
  active?: boolean;
}

export const TableHead = ({
  text,
  className,
  onClick,
  icon,
  active,
}: Props) => {
  const handleTableHeadClick = () => {
    if (!onClick) {
      return;
    }

    onClick();
  };

  return (
    <th
      className={`${className ? className : ''} ${
        icon ? styles.hoverClass : ''
      }`}
      onClick={handleTableHeadClick}
    >
      <span className={active ? styles.activeTableHead : ''}>{text}</span>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={
            styles.sortIcon +
            ' ' +
            (icon === faSortUp || icon === faSortDown ? styles.sortUpDown : '')
          }
          size="sm"
          title={getSortingIconTitle(icon)}
        ></FontAwesomeIcon>
      )}
    </th>
  );
};

const getSortingIconTitle = (icon: IconProp) => {
  if (icon === faSortUp) {
    return 'sorted ascending';
  }
  if (icon === faSortDown) {
    return 'sorted descending';
  }

  return 'sort';
};
