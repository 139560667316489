import { LIVE_POLLS_CHROME_EXTENSION_ID } from 'src/constants/app.constants';
import { IChromeExtensionMsgResponse } from 'src/models/chrome-ext-msg-response.interface';

export const sendMsgToExtension = (
  msgType: string,
  data?: any,
): Promise<IChromeExtensionMsgResponse> => {
  return new Promise<IChromeExtensionMsgResponse>((resolve, _) => {
    chrome.runtime.sendMessage(
      LIVE_POLLS_CHROME_EXTENSION_ID,
      {
        type: msgType,
        data,
      },
      function (response) {
        console.log('response: ', response);
        resolve(response);
      },
    );
  });
};
