import * as React from 'react';
import styles from './Icon.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  icon: IconDefinition;
  color?: string;
  size?: SizeProp;
  dark?: boolean;
  onClick?: React.MouseEventHandler;
  title?: string;
  rotate?: boolean;
}

export default class Icon extends React.Component<Props> {
  public render() {
    const { icon, onClick, dark, size, title, rotate } = this.props;

    const myColor = this.props.color ? this.props.color : '#555';

    return (
      <span
        style={{ color: myColor }}
        className={
          (dark ? styles.iconDark : styles.icon) +
          ' ' +
          (rotate ? styles.rotate : '')
        }
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} size={size} title={title} />
      </span>
    );
  }
}
