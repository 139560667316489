import React, { useEffect, useRef, useState } from 'react';
import { TemplateCard } from 'src/components/template-card/TemplateCard';
import { DEFAULT_TEMPLATE_CATEGORY } from 'src/constants/default-template-category.constants';
import {
  useSearchTemplatesMutation,
  useTemplates,
} from 'src/hooks/store-hooks';
import { ITemplate, ITemplateType } from 'src/models/template.interface';
import { getTemplateCategoriesByTemplateType } from 'src/utils/getTemplateCategories.util';
import Modal from 'src/wick-ui/modal/Modal';
import styles from './all-templates.module.css';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'src/wick-ui/input/TextInput';
import searchIconImg from 'src/images/search-icon.svg';

interface Props {
  closePopup(): void;
}

export const AllTemplatesModal = ({ closePopup }: Props) => {
  const { t } = useTranslation();

  const [templateCategories, setTemplateCategories] = useState(
    getTemplateCategoriesByTemplateType(ITemplateType.QUIZ),
  );

  const searchTemplates = useSearchTemplatesMutation();
  const [templateQueryParams, setTemplateQueryParams] = useState<{
    type: ITemplateType;
    category: string;
  }>({ type: ITemplateType.QUIZ, category: DEFAULT_TEMPLATE_CATEGORY });

  const changeTemplateType = (type: ITemplateType) => {
    setTemplateQueryParams({ type: type, category: DEFAULT_TEMPLATE_CATEGORY });
  };
  const modalWrapperDivRef = useRef<HTMLDivElement | null>(null);
  const [templates, setTemplates] = useState<ITemplate[] | undefined>([]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const categories = getTemplateCategoriesByTemplateType(
      templateQueryParams.type,
    );
    setTemplateCategories(categories);
    setSearchText('');
  }, [templateQueryParams.type]);

  const handleLoadTemplateByType = (selectedType: ITemplateType) => {
    if (selectedType === templateQueryParams.type) {
      return;
    }

    const type =
      templateQueryParams.type === ITemplateType.QUIZ
        ? ITemplateType.POLL
        : ITemplateType.QUIZ;
    changeTemplateType(type);
  };

  const handleLoadTemplateByCategory = (category: string) => {
    setSearchText('');
    setTemplateQueryParams({ ...templateQueryParams, category });
  };

  const handleOpenInnerModal = () => {
    if (modalWrapperDivRef.current) {
      modalWrapperDivRef.current.style.display = 'none';
    }
  };

  const handleCloseInnerModal = () => {
    if (modalWrapperDivRef.current) {
      modalWrapperDivRef.current.style.display = 'block';
    }
  };

  const {
    isError,
    isLoading,
    data: fetchedTemplatesData,
  } = useTemplates({ ...templateQueryParams });

  const handleSearch = async (value: string) => {
    setSearchText(value);
    setShowLoading(true);
    try {
      const searchTemplatesData = await searchTemplates.mutateAsync({
        type: templateQueryParams.type,
        title: value,
      });
      setTemplates(searchTemplatesData);
    } catch (err) {
      setTemplates([]);
      setSearchError(true);
    } finally {
      setShowLoading(false);
    }
  };

  React.useEffect(() => {
    setTemplates(fetchedTemplatesData);
  }, [fetchedTemplatesData]);

  if (isError) {
    throw new Error(t('fetchTemplateError'));
  }
  if (searchError) {
    throw new Error(t('searchTemplateError'));
  }

  return (
    <div ref={modalWrapperDivRef}>
      <Modal closePopup={closePopup} title={t('templates')} popupWidth={1005}>
        <div className={styles.container}>
          <div className={styles.categoryRow}>
            <div className={styles.templateForText}>{t('templatesFor')}</div>
            <div className={styles.quizAndPollToggle}>
              <div
                className={`${styles.templateType} ${styles.activeType} ${
                  templateQueryParams.type === ITemplateType.QUIZ
                    ? styles.activeTemplateType
                    : ''
                }`}
                onClick={() => handleLoadTemplateByType(ITemplateType.QUIZ)}
                data-testid="templateTypeQuiz"
              >
                {t('quiz')}
              </div>
              <div
                className={`${styles.templateType} ${styles.activeType} ${
                  templateQueryParams.type === ITemplateType.POLL
                    ? styles.activeTemplateType
                    : ''
                }`}
                onClick={() => handleLoadTemplateByType(ITemplateType.POLL)}
                data-testid="templateTypePoll"
              >
                {t('poll')}
              </div>
            </div>

            <div className={styles.searchTopic}>
              <TextInput
                maxLength={30}
                onChange={handleSearch}
                placeholder={t('searchForTopic')}
                initialValue={searchText}
                leftIconElement={
                  <img
                    src={searchIconImg}
                    alt="search templates by name"
                    width={14}
                    height={14}
                  />
                }
              />
            </div>
            <div
              className={`${
                styles.categoryTitle
              } ${getActiveCategoryClassOrEmpty(
                DEFAULT_TEMPLATE_CATEGORY,
                templateQueryParams.category,
              )}`}
              onClick={() =>
                handleLoadTemplateByCategory(DEFAULT_TEMPLATE_CATEGORY)
              }
            >
              {t('mostPopular')}
            </div>
            {templateCategories.map(templateCategory => {
              return (
                <div
                  key={templateCategory.name}
                  onClick={() =>
                    handleLoadTemplateByCategory(templateCategory.name)
                  }
                >
                  <div
                    className={`${
                      styles.categoryTitle
                    } ${getActiveCategoryClassOrEmpty(
                      templateCategory.name,
                      templateQueryParams.category,
                    )}`}
                  >
                    {t(templateCategory.displayText)}
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.templateContainer}>
            {templates &&
              templates.length > 0 &&
              templates.map(template => {
                return (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    customClass={styles.gridItem}
                    onOpenInnerModal={handleOpenInnerModal}
                    onCloseInnerModal={handleCloseInnerModal}
                  />
                );
              })}

            {(!templates || templates.length === 0) &&
              !showLoading &&
              !isLoading && (
                <div className={styles.emptyTemplateContainer}>
                  {t('templatesAreNotAvailable')}
                </div>
              )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const getActiveCategoryClassOrEmpty = (
  selectedCategory: string,
  currentCategory: string,
): string => {
  return selectedCategory === currentCategory ? styles.activeCategory : '';
};
