import * as React from 'react';
import styles from './Input.module.css';
import { TEXT_EDITOR_DEBOUNCE_TIME } from 'src/constants/app.constants';

interface Props {
  initialValue?: string;
  onChange?: (newValue: string) => void;
  onChangeWithSideEffects?: (newValue: string) => void;
  maxLength?: number;
  autoFocus?: boolean;
  placeholder?: string;
  leftIconElement?: React.ReactNode;
}

interface State {
  inputValue: string;
}

export class TextInput extends React.Component<Props, State> {
  private static DONE_TYPING_INTERVAL: number = TEXT_EDITOR_DEBOUNCE_TIME;
  private typingTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = { inputValue: this.props.initialValue || '' };
  }

  render() {
    const { inputValue } = this.state;
    const { maxLength, autoFocus, placeholder, leftIconElement } = this.props;

    return (
      <div className={styles.textInputContainer}>
        {!!leftIconElement && (
          <span className={styles.leftIcon}>{leftIconElement}</span>
        )}
        <input
          className={`${styles.textInput} ${
            !!leftIconElement ? styles.textInputWithIcon : ''
          }`}
          type="text"
          value={inputValue}
          onChange={this.handleUpdate}
          autoFocus={autoFocus}
          placeholder={placeholder}
        />
        {maxLength && (
          <span
            className={
              this.isMaxLengthReached()
                ? styles.textInputMaxLengthReached
                : styles.textInputCurrentLength
            }
          >
            {inputValue.length}/{maxLength}
          </span>
        )}
        <div className={styles.line}></div>
      </div>
    );
  }

  private isMaxLengthReached() {
    return this.state.inputValue.length === this.props.maxLength;
  }

  private handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.validity.valid &&
      (e.target.value === '' || this.isValidValue(e.target.value))
    ) {
      this.setState({ inputValue: e.target.value });
      const { onChange } = this.props;

      onChange && onChange(e.target.value || '');

      this.propagateChange();
    }
  };

  private propagateChange = () => {
    const { onChangeWithSideEffects } = this.props;
    if (!onChangeWithSideEffects) {
      return;
    }

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }

    this.typingTimer = setTimeout(() => {
      onChangeWithSideEffects(this.state.inputValue);
    }, TextInput.DONE_TYPING_INTERVAL);
  };

  private isValidValue(value: string) {
    const { maxLength } = this.props;
    if (value && typeof maxLength !== 'undefined' && maxLength > 0) {
      return value.length <= maxLength;
    }

    return true;
  }
}
