export function getFourDigitPin(pin: number) {
  const pinString = pin.toString();
  if (pinString.length >= 4) {
    return pin;
  } else {
    const prependZeroesCount = 4 - pinString.length;
    let appendZero = '';
    for (let j = 0; j < prependZeroesCount; j++) {
      appendZero += '0';
    }
    return (appendZero += pinString);
  }
}
