import styles from './LpPopup.module.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SubmitFeedback } from 'src/interfaces/submit-feedback.interface';
import closeButton from '../../images/close.svg';
import { LpSpinner } from '../spinner/LpSpinner';

interface Props {
  closePopup(): void;
  submitFeedback(response: SubmitFeedback): void;
}

export const LpPopup = ({ submitFeedback, closePopup }: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>('');
  const [rating, setRating] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const maxLength = 500;

  useEffect(() => {
    if (rating > 0 && comment.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [rating, comment]);

  const textChange = (event: any) => {
    setComment(event.target.value);
  };

  const renderCloseBtn = () => {
    return (
      <span
        aria-label="Modal close button"
        className={styles.close}
        onClick={closePopup}
      >
        <img src={closeButton} alt="close modal" />
      </span>
    );
  };

  const submit = async () => {
    if (disabled) {
      return;
    }
    setLoading(true);
    await submitFeedback({ comment, rating });
    setLoading(false);
    closePopup();
  };

  const change = (rating: number) => {
    setRating(rating);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.popupHeading}>
          <div className={styles.headerText}>{t('feedback')}</div>
          <span>{renderCloseBtn()}</span>
        </div>

        <div className={styles.popupContent}>
          <div className={styles.ratingText}>{t('rating')}</div>
          <div className={styles.rate}>
            <input type="radio" id="star5" name="rate" value="5" />
            <label htmlFor="star5" title="Fantastic" onClick={() => change(5)}>
              Fantastic
            </label>
            <input type="radio" id="star4" name="rate" value="4" />
            <label htmlFor="star4" title="Great" onClick={() => change(4)}>
              Great
            </label>
            <input type="radio" id="star3" name="rate" value="3" />
            <label htmlFor="star3" title="Okay" onClick={() => change(3)}>
              Okay
            </label>
            <input type="radio" id="star2" name="rate" value="2" />
            <label htmlFor="star2" title="Meh" onClick={() => change(2)}>
              Meh
            </label>
            <input type="radio" id="star1" name="rate" value="1" />
            <label htmlFor="star1" title="Bad" onClick={() => change(1)}>
              Bad
            </label>
          </div>

          <div>
            <textarea
              className={styles.textInput}
              placeholder="Please write a review"
              rows={1}
              maxLength={500}
              value={comment}
              onChange={textChange}
            />
          </div>
          <div className={styles.characterLength}>
            <span>{comment.length}</span>/{maxLength}
          </div>
          <div className={styles.buttonRow}>
            {!loading && (
              <>
                <div className={styles.cancelLink} onClick={closePopup}>
                  {t('cancel')}
                </div>
                <div
                  className={disabled ? styles.disableButton : styles.button}
                  onClick={submit}
                >
                  {t('submit')}
                </div>
              </>
            )}

            {loading && (
              <div className={styles.spinnerPosition}>
                <LpSpinner customSpinnerClass={styles.spinner} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
