import { POLL_TEMPLATE_CATEGORIES } from 'src/constants/poll-template-categories.constants';
import { QUIZ_TEMPLATE_CATEGORIES } from 'src/constants/quiz-template-categories.constants';
import { ITemplateType } from 'src/models/template.interface';

export const getTemplateCategoriesByTemplateType = (type: ITemplateType) => {
  if (type === ITemplateType.QUIZ) {
    return QUIZ_TEMPLATE_CATEGORIES;
  }
  return POLL_TEMPLATE_CATEGORIES;
};
