import { Header } from './components/header/header';
import styles from './app.module.css';
import Button from './wick-ui/button/Button';
import { useLogin } from './contexts/auth.context';
import { useEffect, useState } from 'react';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';

const SHOW_LOGIN_BTN_AFTER_MS = 3000;

export const UnauthenticatedApp = () => {
  const login = useLogin();
  const classes = `${styles.mainContainer} ${styles.globalError}`;
  const [showLoginBtn, setShowLoginBtn] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoginBtn(true);
    }, SHOW_LOGIN_BTN_AFTER_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <div className={styles.navbarContainer}>
        <Header />
      </div>
      <div className={classes}>
        {!showLoginBtn && <LpSpinner />}
        {showLoginBtn && (
          <div className={styles.sessionExpiredTextWithButton}>
            <span className={styles.sessionExpiredText}>Session expired</span>
            <Button text="Login now" onClick={() => login()} />
          </div>
        )}
      </div>
    </>
  );
};
