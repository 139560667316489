import styles from './api-state-display.module.css';

/* const resolveApiState = (apiStore: ApiStore) => {
  if (apiStore.fetching) {
    return 'Loading...';
  }
  if (apiStore.saving) {
    return 'Saving...';
  }

  if (apiStore.error) {
    return 'API Error';
  }

  return 'All changes saved';
}; */

export const ApiStateDisplay = () => {
  return <span className={styles.container}>{}</span>;
};
