import React from 'react';
import styles from './Card.module.css';
import { CardContext } from './CardContext';

interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
}

export default class CardBody extends React.Component<Props> {
  public render() {
    const { onClick, children } = this.props;

    return (
      <div className={styles.cardBody} onClick={onClick}>
        {children}
      </div>
    );
  }
}

CardBody.contextType = CardContext;
