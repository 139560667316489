import * as React from 'react';
import styles from './LpSpinner.module.css';

interface Props {
  color?: string;
  message?: string;
  count?: number;
  customSpinnerClass?: string;
  customMainSpinnerClass?: string;
}

export class LpSpinner extends React.Component<Props> {
  public render() {
    const {
      color,
      message,
      count,
      customSpinnerClass,
      customMainSpinnerClass,
    } = this.props;
    return (
      <div data-testid="spinner" className={`${customMainSpinnerClass || ''}`}>
        <div
          className={`${styles.spinner} ${customSpinnerClass || ''}`}
          style={color ? { backgroundColor: color } : {}}
        >
          <div className={styles.bounce1}></div>
          <div className={styles.bounce2}></div>
          <div className={styles.bounce3}></div>
        </div>
        <div
          className={styles.loader}
          style={color ? { borderTopColor: color } : {}}
        />
        <div className={styles.loaderMessage}>
          {message} {count && count > 0 ? [<span>{count}</span>] : ''}
        </div>
      </div>
    );
  }
}
