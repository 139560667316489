import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { ILivePollEditorStore } from './useLivePollEditor.store';

export const livePollIdSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.id;
export const addAnswerMutationSelector = (state: ILivePollEditorStore) =>
  state.addAnswerMutation;
export const addSwipeQuestionCardMutationSelector = (
  state: ILivePollEditorStore,
) => state.addSwipeQuestionCardMutation;
export const addProfileQuestionAnswerMutationSelector = (
  state: ILivePollEditorStore,
) => state.addProfileQuestionAnswerMutation;
export const livePollTypeSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.type;
export const updateAnswerMutationSelector = (state: ILivePollEditorStore) =>
  state.updateAnswerMutation;
export const updateProfileQuestionAnswerMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateProfileQuestionAnswerMutation;
export const deleteAnswerMutationSelector = (state: ILivePollEditorStore) =>
  state.deleteAnswerMutation;
export const deleteProfileQuestionAnswerMutationSelector = (
  state: ILivePollEditorStore,
) => state.deleteProfileQuestionAnswerMutation;
export const deleteSwipeQuestionCardMutationSelector = (
  state: ILivePollEditorStore,
) => state.deleteSwipeQuestionCardMutation;
export const reorderAnswerListMutationSelector = (
  state: ILivePollEditorStore,
) => state.reorderAnswerListMutation;
export const reorderProfileQuestionAnswerListMutationSelector = (
  state: ILivePollEditorStore,
) => state.reorderProfileQuestionAnswerListMutation;
export const updateQuestionTitleMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateQuestionTitleMutation;

export const updateSwipeQuestionPropertiesSelector = (
  state: ILivePollEditorStore,
) => state.updateSwipeQuestionPropertiesMutation;
export const updateProfileQuestionTitleMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateProfileQuestionTitleMutation;
export const setFocusQuestionIdSelector = (state: ILivePollEditorStore) =>
  state.setFocusQuestionId;
export const focusQuestionIdSelector = (state: ILivePollEditorStore) =>
  state.focusQuestionId;
export const focusAnswerIdSelector = (state: ILivePollEditorStore) =>
  state.focusAnswerId;
export const activeQuestionIdSelector = (state: ILivePollEditorStore) =>
  state.activeQuestionId;
export const activeProfileQuestionIdSelector = (state: ILivePollEditorStore) =>
  state.activeProfileQuestionId;
export const setActiveQuestionIdSelector = (state: ILivePollEditorStore) =>
  state.setActiveQuestionId;
export const setActiveProfileQuestionIdSelector = (
  state: ILivePollEditorStore,
) => state.setActiveProfileQuestionId;
export const addQuestionMutationSelector = (state: ILivePollEditorStore) =>
  state.addQuestionMutation;
export const addGeneratedQuestionsMutationSelector = (
  state: ILivePollEditorStore,
) => state.addGeneratedQuestionsMutation;
export const addProfileQuestionMutationSelector = (
  state: ILivePollEditorStore,
) => state.addProfileQuestionMutation;
export const questionSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.questions;
export const questionsCountSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.questions?.length;
export const choiceQuestionSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.questions?.filter(
    question => question.type === QuestionType.CHOICE,
  );
export const profileQuestionSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.profileQuestions;
export const deleteQuestionMutationSelector = (state: ILivePollEditorStore) =>
  state.deleteQuestionMutation;
export const deleteProfileQuestionMutationSelector = (
  state: ILivePollEditorStore,
) => state.deleteProfileQuestionMutation;
export const livePollTitleSelector = (state: ILivePollEditorStore) =>
  state.livePoll?.title;
export const updateLivePollTitleMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateLivePollTitleMutation;
export const isErrorSelector = (state: ILivePollEditorStore) => state.isError;
export const errorSelector = (state: ILivePollEditorStore) => state.error;
export const resetStore = (state: ILivePollEditorStore) => state.resetStore;
export const setFocusAnswerIdSelector = (state: ILivePollEditorStore) =>
  state.setFocusAnswerId;
export const updateQuestionImageMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateQuestionImageMutation;
export const updatePermanentRoomToggleMutationSelector = (
  state: ILivePollEditorStore,
) => state.updatePermanentRoomToggleMutation;
export const updateProfileQuestionImageMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateProfileQuestionImageMutation;
export const reorderQuestionListMutationSelector = (
  state: ILivePollEditorStore,
) => state.reorderQuestionListMutation;
export const reorderProfileQuestionListMutationSelector = (
  state: ILivePollEditorStore,
) => state.reorderProfileQuestionListMutation;
export const enableProfileQuestionsSettingSelector = (
  state: ILivePollEditorStore,
) => state.livePoll?.setting?.enableProfileQuestions;
export const updateLivePollSettingMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateLivePollSettingMutation;
export const enableQuestionDifficultySelector = (state: ILivePollEditorStore) =>
  state.livePoll?.setting?.enableQuestionDifficulty;
export const questionDifficultySettingsSelector = (
  state: ILivePollEditorStore,
) => state.livePoll?.setting?.questionDifficultySetting;
export const updateQuestionDifficultyLevelMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateQuestionDifficultyLevelMutation;
export const updateWordCloudQuestionResponseLimitMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateWordCloudQuestionResponseLimitMutation;
export const updateGeneratedQuestionsReviewedStatusSelector = (
  state: ILivePollEditorStore,
) => state.updateGeneratedQuestionsReviewStatusMutation;
export const updateILiveResultChartTypeSelector = (
  state: ILivePollEditorStore,
) => state.updateLiveResultChartTypeMutation;
export const addOptionsForPollSelector = (state: ILivePollEditorStore) =>
  state.addOptionsForScaleQuestionMutation;
export const liveResultChartTypeSelector = (state: ILivePollEditorStore) =>
  state.findLiveResultChartType;
export const livePollIsSavingSelector = (state: ILivePollEditorStore) =>
  state.isSaving;
export const setIsSavingSelector = (state: ILivePollEditorStore) =>
  state.setIsSaving;
export const livePollSelector = (state: ILivePollEditorStore) => state.livePoll;
export const markQuestionAsMultiSelectSelector = (
  state: ILivePollEditorStore,
) => state.markQuestionAsMultiSelect;
export const updateQuestionPresenterNotesMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateQuestionPresenterNotesMutation;
export const updateSwipeQuestionMutationSelector = (
  state: ILivePollEditorStore,
) => state.updateSwipeQuestionCardMutation;
export const deleteQuestionFactSelector = (state: ILivePollEditorStore) =>
  state.deleteQuestionFactMutation;
export const setMappedLivePollFromSlidesSelector = (
  state: ILivePollEditorStore,
) => state.setMappedLivePollFromSlide;
export const isLoadingSelector = (state: ILivePollEditorStore) =>
  state.isLoading;
export const mappedLivePollOnSlidesSelector = (state: ILivePollEditorStore) =>
  state.mappedLivePollOnSlides;
export const mapLivePollToSlideSelector = (state: ILivePollEditorStore) =>
  state.mapLivePollToSlide;
export const addQuestionFactsWithAiSelector = (state: ILivePollEditorStore) =>
  state.addQuestionFactsWithAiMutation;
