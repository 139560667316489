export const QUIZ_TEMPLATE_CATEGORIES = [
  {
    name: 'Food & Drink',
    colors: { primary: '#f4896c', secondary: '#F9A189' },
    displayText: 'foodAndDrink',
  },
  {
    name: 'General',
    colors: { primary: '#1b3380', secondary: '#213C8F' },
    displayText: 'general',
  },
  {
    name: 'Holidays',
    colors: { primary: '#882255', secondary: '#AB3570' },
    displayText: 'holidays',
  },
  {
    name: 'Music',
    colors: { primary: '#60ab9e', secondary: '#56C3B1' },
    displayText: 'music',
  },
  {
    name: 'Places',
    colors: { primary: '#e78c35', secondary: '#F1A052' },
    displayText: 'places',
  },
  {
    name: 'Random',
    colors: { primary: '#6f4c9b', secondary: '#906BBF' },
    displayText: 'random',
  },
  {
    name: 'Sports',
    colors: { primary: '#4d8ac6', secondary: '#639ED8' },
    displayText: 'sports',
  },
  {
    name: 'TV & Movies',
    colors: { primary: '#da2222', secondary: '#EC3737' },
    displayText: 'tvAndMovies',
  },
];
