import styles from './TemplateCard.module.css';
import {
  faChartLine,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITemplate, ITemplateType } from 'src/models/template.interface';
import { TemplateBackgroundSvg } from '@livepolls/ui-components/src/components/svgImages/template-background.svg';
import { getTemplateColorByCategory } from 'src/utils/getTemplateColorByCategory.util';
import { useState } from 'react';
import { AddLivePollFromTemplateModal } from '../add-poll-from-template/add-livepoll-from-template-modal';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
interface Props {
  template: ITemplate;
  customClass?: string;
  onOpenInnerModal?: () => void;
  onCloseInnerModal?: () => void;
}

export const TemplateCard = ({
  template,
  customClass,
  onOpenInnerModal,
  onCloseInnerModal,
}: Props) => {
  const { t } = useTranslation();
  const modalRoot = document.getElementById('modal-root') as HTMLElement;
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const handleSelectTemplate = () => {
    onOpenInnerModal && onOpenInnerModal();
    setShowPreviewModal(true);
  };

  let icon = faChartLine;
  if (template.type === ITemplateType.QUIZ) {
    icon = faGraduationCap;
  }

  const colors = getTemplateColorByCategory(template.category, template.type);

  const closePopup = () => {
    onCloseInnerModal && onCloseInnerModal();
    setShowPreviewModal(false);
  };

  const addLivePollFromTemplateModal = (
    <AddLivePollFromTemplateModal template={template} closePopup={closePopup} />
  );

  return (
    <div
      className={customClass ? customClass : styles.gridItem}
      data-testid="templateCard"
    >
      <div className={styles.card} onClick={() => handleSelectTemplate()}>
        <div className={styles.cardBackground}>
          {TemplateBackgroundSvg(colors)}
        </div>
        <div className={styles.row}>
          <div className={styles.iconAndTemplateType}>
            <FontAwesomeIcon
              icon={icon}
              className={styles.icon}
              width="16px"
              height="16px"
            />
            <span>{getTemplateType(t, template.type)}</span>
          </div>
          <div className={styles.totalQuestions}>
            <span>{getTotalQuestionsText(t, template.questionCount)}</span>
          </div>
        </div>

        <div className={styles.templateTitle}>{getTitle(template.title)}</div>
      </div>

      {showPreviewModal &&
        ReactDOM.createPortal(addLivePollFromTemplateModal, modalRoot)}
    </div>
  );
};

const getTitle = (title: string) => {
  if (title.length > 80) {
    return title.substring(0, 75) + '...';
  }
  return title;
};

const getTotalQuestionsText = (t: (key: string) => {}, length: number) => {
  if (length === 1) {
    return `1 Question`;
  }
  return `${length} ${t('questions')}`;
};

const getTemplateType = (
  t: (key: string) => any,
  templateType: ITemplateType,
) => {
  const type = templateType === ITemplateType.POLL ? t('poll') : t('quiz');
  return type;
};
