import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../constants/app.constants';

export const callApi = (endpoint: string, config?: AxiosRequestConfig) => {
  return axios({
    url: `${API_URL}${endpoint}`,
    withCredentials: true,
    ...config,
  });
};

export const getApi = async (endpoint: string, config?: AxiosRequestConfig) => {
  const response = await callApi(endpoint, { method: 'GET', ...config });
  return response.data as any;
};

export const postApi = async (
  endpoint: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const response = await callApi(endpoint, { method: 'POST', data, ...config });
  return response.data as any;
};

export const putApi = (
  endpoint: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  return callApi(endpoint, { method: 'PUT', data, ...config });
};

export const patchApi = async (
  endpoint: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const response = await callApi(endpoint, {
    method: 'PATCH',
    data,
    ...config,
  });

  return response.data as any;
};

export const deleteApi = async (
  endpoint: string,
  data: any = {},
  config?: AxiosRequestConfig,
) => {
  const response = await callApi(endpoint, {
    method: 'DELETE',
    data,
    ...config,
  });

  return response.data as any;
};
