import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'src/utils/hooks.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { THIRD_PARTY_APP_TYPE_SESSION_STORAGE_KEY } from 'src/constants/app.constants';
import { ThirdPartyAppType } from 'src/models/third-party-app-type.enum';
import { initializeZoomSdk } from 'src/utils/zoom-sdk.utils';
import { ConfigResponse } from '@zoom/appssdk';

const initializeSdkOrResolve = async (appType: string | null) => {
  if (!appType) {
    return Promise.resolve();
  } else {
    return await initializeZoomSdk();
  }
};

const getAppTypeFromSessionStorage = (): ThirdPartyAppType | null => {
  const appType = sessionStorage.getItem(
    THIRD_PARTY_APP_TYPE_SESSION_STORAGE_KEY,
  ) as ThirdPartyAppType;
  return appType || null;
};

interface IThirdPartyAppIntegrationContext {
  thirdPartyAppType: ThirdPartyAppType | null;
  zoomConfigResponse: ConfigResponse | null;
  setThirdPartyAppType: (thirdPartyAppType: ThirdPartyAppType) => void;
}

const ThirdPartyAppIntegrationContext = createContext<
  IThirdPartyAppIntegrationContext | undefined
>(undefined);
ThirdPartyAppIntegrationContext.displayName = 'ThirdPartyAppIntegrationContext';

function ThirdPartyAppIntegrationProvider(props: any): JSX.Element {
  const [thirdPartyAppType, setThirdPartyAppType] =
    useState<ThirdPartyAppType | null>(getAppTypeFromSessionStorage());

  const {
    data: zoomConfigResponse,
    error,
    isError,
    isIdle,
    isLoading,
    isSuccess,
    status,
    run,
  } = useAsync();

  useEffect(() => {
    const promise = initializeSdkOrResolve(thirdPartyAppType);
    run(promise);
  }, [run, thirdPartyAppType]);

  const value = useMemo(
    () => ({
      thirdPartyAppType,
      zoomConfigResponse,
      setThirdPartyAppType,
    }),
    [thirdPartyAppType, zoomConfigResponse],
  );

  if (isError) {
    return <div>Oops an error occurred. {error?.message}</div>;
  }

  if (
    isLoading ||
    isIdle ||
    (thirdPartyAppType === ThirdPartyAppType.ZOOM_APP &&
      zoomConfigResponse == null)
  ) {
    return <LpSpinner message="Loading..." />;
  }

  if (isSuccess) {
    return (
      <ThirdPartyAppIntegrationContext.Provider value={value} {...props} />
    );
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useThirdPartyAppIntegration() {
  const context = useContext(ThirdPartyAppIntegrationContext);
  if (context === undefined) {
    throw new Error(
      `useThirdPartyAppIntegration must be used within a ThirdPartyAppIntegrationProvider`,
    );
  }

  return context;
}

export { ThirdPartyAppIntegrationProvider, useThirdPartyAppIntegration };
