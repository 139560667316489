import {
  faCheckDouble,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LpNewToolTip } from '@livepolls/ui-components/src/components/new-tooltip/LpNewToolTip';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAKE_LIVEPOLL_URL } from 'src/constants/app.constants';
import { ILivePollWithPermanentRoom } from 'src/models/livepoll.interface';
import {
  isLoadingSelector,
  livePollIsSavingSelector,
  livePollSelector,
  mapLivePollToSlideSelector,
  mappedLivePollOnSlidesSelector,
  setIsSavingSelector,
  setMappedLivePollFromSlidesSelector,
  updatePermanentRoomToggleMutationSelector,
} from 'src/stores/livePoll-store.selectors';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import { CopyPermanentRoomLinkSvg } from 'src/svgImages/copy-permanent-room.svg';
import { PollSvg } from 'src/svgImages/poll.svg';
import {
  useIsGoogleSlidesUrlActive,
  useOptLivePollIdFromUrl,
} from 'src/utils/hooks.util';
import { ToggleSwitch } from 'src/wick-ui/input/ToggleSwitch';
import { SecondaryNavbar as WickSecondaryNavbar } from 'src/wick-ui/navbar/SecondaryNavbar';
import { StartPollSplitButton } from '../start-livepoll/start-livepoll-split-button';
import { URLS } from './app.urls';
import styles from './livepoll-editor-navbar.module.css';
import Button from 'src/wick-ui/button/Button';
import { matchUrls } from 'src/utils/url.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { ILivePollWithStats } from 'src/models/livepoll-total-respondents-and-sessions.interface';
import { SHOW_TOAST_TIMEOUT } from '@livepolls/ui-components/src/constants/app-common.constants';
import { LpToast } from '@livepolls/ui-components/src/components/toast/LpToast';

interface SecondaryNavLink {
  url: string;
  text: string;
  imageUrl: string;
}

enum ShowTextStage {
  DEFAULT = 0,
  DISPLAY_TEXT_AFTER_SOMETIME = 1,
  DISPLAY_TEXT = 2,
}

export const LivePollEditorNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    location?.pathname || '',
  );
  const [showAllChangesSaved, setShowAllChangesSaved] = useState<ShowTextStage>(
    ShowTextStage.DEFAULT,
  );
  const [showToast, setShowToast] = useState<boolean>(false);

  const setIsSaving = useLivePollEditorStore(setIsSavingSelector);
  const updatePermanentRoomToggle = useLivePollEditorStore(
    updatePermanentRoomToggleMutationSelector,
  );

  const isSaving = useLivePollEditorStore(livePollIsSavingSelector);
  const timeoutRef1 = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef2 = useRef<NodeJS.Timeout | null>(null);
  const showToastTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const activeLivePollId = useOptLivePollIdFromUrl();
  const isLivePollEditorForSlideActive = useIsGoogleSlidesUrlActive();

  const livePoll = useLivePollEditorStore(livePollSelector);
  const isLoading = useLivePollEditorStore(isLoadingSelector);
  const setMappedLivePollFromSlides = useLivePollEditorStore(
    setMappedLivePollFromSlidesSelector,
  );
  const mappedLivePollFromSlides = useLivePollEditorStore(
    mappedLivePollOnSlidesSelector,
  );
  const mapLivePollToSlide = useLivePollEditorStore(mapLivePollToSlideSelector);

  const permanentRoom = (livePoll as ILivePollWithPermanentRoom)?.permanentRoom;
  const permanentRoomEnabled = !!permanentRoom?.enable;

  useEffect(() => {
    return () => {
      timeoutRef1.current && clearTimeout(timeoutRef1.current);
      timeoutRef2.current && clearTimeout(timeoutRef2.current);
      showToastTimeoutRef.current && clearTimeout(showToastTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (isSaving && showAllChangesSaved === ShowTextStage.DEFAULT) {
      setShowAllChangesSaved(ShowTextStage.DISPLAY_TEXT_AFTER_SOMETIME);
    } else if (
      !isSaving &&
      showAllChangesSaved === ShowTextStage.DISPLAY_TEXT_AFTER_SOMETIME
    ) {
      setShowAllChangesSaved(ShowTextStage.DISPLAY_TEXT);
      timeoutRef2.current = setTimeout(() => {
        setShowAllChangesSaved(ShowTextStage.DEFAULT);
      }, 2000);
    }
  }, [isSaving, showAllChangesSaved]);

  useEffect(() => {
    if (isSaving) {
      timeoutRef1.current = setTimeout(() => {
        setIsSaving(false);
      }, 2000);
    }
  }, [isSaving, setIsSaving]);

  useEffect(() => {
    if (isLivePollEditorForSlideActive) {
      setMappedLivePollFromSlides();
    }
  }, [isLivePollEditorForSlideActive, setMappedLivePollFromSlides]);

  useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
    }
  }, [currentLocation, location]);

  useEffect(() => {
    if (showToast) {
      showToastTimeoutRef.current = setTimeout(() => {
        setShowToast(false);
      }, SHOW_TOAST_TIMEOUT);
    }
  }, [showToast]);

  if (!activeLivePollId) {
    return null;
  }

  const livePollType = livePoll?.type;
  const navItems = [
    {
      text: t('workspace'),
      url: getLivePollEditorUrl(
        isLivePollEditorForSlideActive,
        activeLivePollId,
      ),
      imageUrl:
        'https://www.questionpro.com/images/appnavigation/navicon/workspace.svg',
    },
    {
      text: t('design'),
      url: getLivePollDesignUrl(
        isLivePollEditorForSlideActive,
        activeLivePollId,
      ),
      imageUrl:
        'https://www.questionpro.com/images/appnavigation/navicon/interface.svg',
    },
  ];

  if (!isAnyRouteActive(currentLocation, navItems)) {
    return null;
  }

  const activeNavItems = navItems
    .filter(n => canDisplayNavItem(currentLocation, n, navItems))
    .map(link => ({
      ...link,
      active: isRouteActive(currentLocation, link),
    }));

  const displaySavingText = () => {
    if (isSaving) {
      return (
        <div className={styles.isSaving}>
          <FontAwesomeIcon icon={faCloudUploadAlt} />
          <span>Saving...</span>
        </div>
      );
    } else if (showAllChangesSaved === ShowTextStage.DISPLAY_TEXT) {
      return (
        <div className={styles.allChangesSavedText}>
          <FontAwesomeIcon icon={faCheckDouble} />
          <span>All changes saved</span>
        </div>
      );
    }
  };

  const handleToggle = async () => {
    const sessionId = (livePoll as ILivePollWithPermanentRoom)?.permanentRoom
      .sessionId;
    await updatePermanentRoomToggle(sessionId, livePoll?.title);
  };

  const createPermanentRoomUrl = () => {
    const { permanentRoom } = livePoll as ILivePollWithPermanentRoom;
    const takeLivePollURL = `${TAKE_LIVEPOLL_URL}/room/${permanentRoom.id}`;
    return takeLivePollURL;
  };

  const copyPermanentRoomLink = () => {
    if (permanentRoomEnabled && navigator.clipboard && window.isSecureContext) {
      const url = createPermanentRoomUrl();
      navigator.clipboard.writeText(url);
      setShowToast(true);
    }
  };

  const navigateToPermanentRoom = () => {
    if (permanentRoomEnabled) {
      const { permanentRoom } = livePoll as ILivePollWithPermanentRoom;
      const livePollId = livePoll?.id;
      const sessionId = permanentRoom.sessionId;

      navigate(
        `livepolls/${livePollId}/livepoll-sessions/${sessionId}/permanent-room/dashboard`,
      );
    }
  };

  return (
    <WickSecondaryNavbar secondaryNavLinks={activeNavItems}>
      {displaySavingText()}

      {showToast && (
        <LpToast
          text="Join permanent room link copied to clipboard"
          onClose={() => {
            setShowToast(false);
          }}
        />
      )}
      {!isLivePollEditorForSlideActive && (
        <>
          {livePollType === LivePollType.POLL && (
            <div className={styles.permanentRoom}>
              <div className={styles.permanentRoomToggle}>
                {t('permanentRoom')}
                <div className={styles.toggle}>
                  <ToggleSwitch
                    initialValue={permanentRoomEnabled}
                    onChange={handleToggle}
                  />
                </div>
              </div>

              <div className={styles.permanentRoomUrl}>
                <LpNewToolTip
                  textOnHover={t('copyPermanentRoomLink')}
                  toolTipTextCustomClass={styles.copyLinkClass}
                  showToolTip={permanentRoomEnabled}
                >
                  <div
                    className={
                      permanentRoomEnabled
                        ? styles.copyLink
                        : styles.disabledCopyLink
                    }
                    onClick={copyPermanentRoomLink}
                  >
                    <span
                      className={styles.permanentRoomImg}
                      data-testid="copyPermanentRoomLinkImg"
                    >
                      <CopyPermanentRoomLinkSvg />
                    </span>
                  </div>
                </LpNewToolTip>

                <LpNewToolTip
                  textOnHover={t('seeLiveResults')}
                  toolTipTextCustomClass={styles.analyticsClass}
                  showToolTip={permanentRoomEnabled}
                >
                  <div
                    className={
                      permanentRoomEnabled
                        ? styles.joinAsPresenterText
                        : styles.disabledJoinAsPresenterText
                    }
                    onClick={navigateToPermanentRoom}
                  >
                    <span
                      className={styles.permanentRoomImg}
                      data-testid="seeLiveResultsImg"
                    >
                      <PollSvg />
                    </span>
                  </div>
                </LpNewToolTip>
              </div>
            </div>
          )}

          <div className={styles.right}>
            <StartPollSplitButton />
          </div>
        </>
      )}

      {isLivePollEditorForSlideActive && (
        <>
          {isLoading && <LpSpinner />}

          {!isLoading && (
            <>
              {mappedLivePollFromSlides?.id !== livePoll?.id ? (
                <Button
                  text="Add to Presentation"
                  onClick={() =>
                    mapLivePollToSlide(livePoll as ILivePollWithStats)
                  }
                />
              ) : (
                <div className={styles.addedToPresentationText}>
                  Added to Presentation
                </div>
              )}
            </>
          )}
        </>
      )}
    </WickSecondaryNavbar>
  );
};

const isAnyRouteActive = (
  currentLocation: string,
  navItems: SecondaryNavLink[],
): boolean => {
  return navItems.find(n => isRouteActive(currentLocation, n)) != null;
};

const isRouteActive = (
  currentLocation: string,
  navItem: SecondaryNavLink,
): boolean => {
  return matchUrls(currentLocation, navItem.url);
};

const canDisplayNavItem = (
  currentLocation: string,
  navItem: SecondaryNavLink,
  navItems: SecondaryNavLink[],
): boolean => {
  const listNavItem = navItems.find(n => n.url === URLS.LIVE_POLL_LIST_URL);
  if (
    listNavItem != null &&
    navItem.url !== URLS.LIVE_POLL_LIST_URL &&
    isRouteActive(currentLocation, listNavItem)
  ) {
    return false;
  }

  return true;
};

const getLivePollEditorUrl = (
  isLivePollEditorForSlideActive: boolean,
  activeLivePollId: number,
) => {
  let url = URLS.EDIT_LIVE_POLL_URL as string;

  if (isLivePollEditorForSlideActive) {
    url = URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL;
  }

  return url.replace(':livePollId', `${activeLivePollId}`);
};

const getLivePollDesignUrl = (
  isLivePollEditorForSlideActive: boolean,
  activeLivePollId: number,
) => {
  let url = URLS.DESIGN_LIVE_POLL_URL as string;
  if (isLivePollEditorForSlideActive) {
    url = URLS.LIVE_POLL_DESIGN_URL_FOR_SLIDES;
  }

  return url.replace(':livePollId', `${activeLivePollId}`);
};
