import { THIRD_PARTY_APP_TYPE_SESSION_STORAGE_KEY } from 'src/constants/app.constants';
import styles from './ZoomApp.module.css';
import { redirectTo } from 'src/utils/redirect.util';
import { URLS, URL_PREFIX } from 'src/components/navbar/app.urls';
import { useEffect } from 'react';
import presenterSvg from 'src/images/presenter.svg';
import screenUsersSvg from 'src/images/screen-users.svg';
import { ThirdPartyAppType } from 'src/models/third-party-app-type.enum';
import { useThirdPartyAppIntegration } from 'src/contexts/ThirdPartyAppsContext';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { isZoomRunningInMobile } from 'src/utils/zoom-sdk.utils';

const TAKE_LIVEPOLL_URL = `https://www.questionpro.io`;
const goToTakeApp = () => {
  redirectTo(TAKE_LIVEPOLL_URL);
};

const goToPresenterLogin = () => {
  redirectTo(`${URL_PREFIX}${URLS.LIVE_POLL_LIST_URL}`);
};

export const ZoomApp = () => {
  const { setThirdPartyAppType, zoomConfigResponse } =
    useThirdPartyAppIntegration();
  useEffect(() => {
    sessionStorage.setItem(
      THIRD_PARTY_APP_TYPE_SESSION_STORAGE_KEY,
      ThirdPartyAppType.ZOOM_APP,
    );

    setThirdPartyAppType(ThirdPartyAppType.ZOOM_APP);
  }, [setThirdPartyAppType]);

  if (zoomConfigResponse == null) {
    return <LpSpinner message="Loading..." />;
  }

  const zoomRunningInMobile = isZoomRunningInMobile(zoomConfigResponse.product);
  return (
    <div className={styles.container}>
      <div className={styles.titleText}>Make your Zoom meetings awesome! </div>
      <div className={styles.participant} onClick={() => goToTakeApp()}>
        <div>
          <img src={screenUsersSvg} alt="" className={styles.screenUserImg} />
        </div>
        <div>
          <span className={styles.heading}>Join as participant</span>
          <span className={styles.informationText}>
            Engage in a LivePoll hosted by someone
          </span>
        </div>
      </div>
      {!zoomRunningInMobile && (
        <div className={styles.host} onClick={() => goToPresenterLogin()}>
          <div>
            <img src={presenterSvg} alt="" className={styles.presenterImg} />
          </div>
          <div>
            <span className={styles.heading}>Host a LivePoll</span>
            <span className={styles.informationText}>
              Create an engaging session for your audience
            </span>
          </div>
        </div>
      )}
      <div className={styles.poweredByText}>
        Powered by <span className={styles.companyName}>QuestionPro</span>
      </div>
    </div>
  );
};
