export const TemplateBackgroundSvg = (colors: {
  primary: string;
  secondary: string;
}) => {
  const image = (
    <svg
      width="210"
      height="86"
      viewBox="0 0 210 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1402_22485)">
        <g clipPath="url(#clip1_1402_22485)">
          <rect width="210" height="86" fill={colors.primary} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.682 42.3733C196.129 39.8055 208.856 55.2292 218.082 66.2583C225.202 74.7699 221.552 84.87 222.535 94.7966C223.663 106.192 233.714 118.649 224.169 127.744C214.293 137.154 194.96 135.737 178.682 136.579C160.45 137.524 139.267 142.067 126.19 132.817C113.075 123.54 113.775 107.383 119.613 94.7966C124.253 84.7917 143.376 84.074 152.589 75.8972C164.23 65.5656 160.639 45.0287 178.682 42.3733Z"
            fill={colors.secondary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8228 70.1086C32.5485 74.6791 51.7153 72.0209 68.872 66.4301C83.2362 61.7493 90.3899 51.4608 99.9274 42.376C111.949 30.9254 133.669 21.6559 130.831 7.41163C127.934 -7.13193 106.808 -16.898 87.3167 -20.9325C70.9805 -24.3139 56.3791 -14.8966 40.1046 -11.368C24.7284 -8.03411 7.71357 -9.08031 -4.00558 -1.1319C-20.2604 9.89283 -39.2707 23.7745 -35.0812 39.6262C-30.8264 55.7253 -6.48154 64.6154 14.8228 70.1086Z"
            fill={colors.secondary}
          />
        </g>
      </g>
    </svg>
  );
  return image;
};
