import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'src/wick-ui/button/Button';
import styles from './LivePollListTopBar.module.css';
import { useTranslation } from 'react-i18next';
interface Props {
  onAddNewLivePoll: () => void;
}

export const LivePollListTopBar = ({ onAddNewLivePoll }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.topBarContainer} data-testid="topbar">
      <div className={styles.newLivePollContainer}>
        <Button
          text={t('createNew')}
          leftIcon={faPlus}
          customBtnClass={styles.newLivePollBtn}
          onClick={onAddNewLivePoll}
          iconSize="sm"
        />
      </div>
    </div>
  );
};
