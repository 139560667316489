import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import { getApi } from 'src/utils/api.util';

const getTranslations = () => {
  return getApi('/language-translations');
};

const backendOptions: HttpBackendOptions = {
  request: function (options, url, payload, callback) {
    getTranslations()
      .then(translations => {
        callback(null, {
          data: translations,
          status: 200,
        });
      })
      .catch(err => {
        console.error(err);
        callback(err, {
          status: 500,
          data: {},
        });
      });
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
