import {
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { useEffect, useRef, useState } from 'react';
import { searchLivePolls } from 'src/hooks/store-hooks';
import { LivePollListSortBy } from 'src/models/livepoll-list-sort-by.enum';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import Button from 'src/wick-ui/button/Button';
import styles from './SearchLivePollsByName.module.css';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'src/models/sort-order.enum';

interface Props {
  onTableHeadClick: (sortingParams: IFetchLivePollsSortingParams) => void;
  onPopupClick: (sortingParams: IFetchLivePollsSortingParams) => void;
  searchText: string | null;
  sortingParams: IFetchLivePollsSortingParams;
  closePopup(): void;
}

export const SearchLivePollsByName = ({
  onPopupClick,
  onTableHeadClick,
  sortingParams,
  searchText,
  closePopup,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useRef(false);

  const [titleText, setTitleText] = useState<string | null>(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchedLivePolls, setSearchedLivePolls] = useState<
    { title: string }[]
  >([]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!titleText) {
      setSearchedLivePolls([]);
      return;
    }

    (async () => {
      setIsLoading(true);
      const result = await searchLivePolls(titleText);
      if (mounted.current) {
        setSearchedLivePolls(result);
        setIsLoading(false);
      }
    })();
  }, [titleText]);

  const setTitle = (event: any) => {
    const value = event.target.value;
    setTitleText(value);
  };

  const filterLivePolls = () => {
    onTableHeadClick({
      sortBy: LivePollListSortBy.NAME,
      sortOrder: SortOrder.ASC,
      title: titleText,
    });
    closePopup();
  };

  const clear = () => {
    setTitleText(null);
    closePopup();

    onTableHeadClick({
      sortBy: sortingParams.sortBy,
      sortOrder: sortingParams.sortOrder,
      title: '',
    });
  };

  const isSortedByNameInAsc =
    sortingParams.sortBy === LivePollListSortBy.NAME &&
    sortingParams.sortOrder === SortOrder.ASC;

  const isSortedByNameInDesc =
    sortingParams.sortBy === LivePollListSortBy.NAME &&
    sortingParams.sortOrder === SortOrder.DESC;

  const displayFetchedLivePolls = !!(
    searchedLivePolls &&
    searchedLivePolls.length > 0 &&
    !isLoading
  );

  const displayNoLivPollMessage = !!(
    titleText &&
    titleText.length > 0 &&
    searchedLivePolls.length === 0 &&
    !isLoading
  );

  return (
    <div className={styles.dropdown}>
      <div
        className={`${styles.sortBy} ${
          isSortedByNameInAsc ? styles.sortByActiveColor : ''
        } `}
        onClick={() =>
          onPopupClick({
            sortBy: LivePollListSortBy.NAME,
            sortOrder: SortOrder.ASC,
            title: titleText,
          })
        }
      >
        <FontAwesomeIcon icon={faSortAlphaDown} opacity=".8" />
        <span className={styles.sortByText}>{t('sort')} A-Z</span>
      </div>
      <div
        className={`${styles.sortBy} ${
          isSortedByNameInDesc ? styles.sortByActiveColor : ''
        } `}
        onClick={() =>
          onPopupClick({
            sortBy: LivePollListSortBy.NAME,
            sortOrder: SortOrder.DESC,
            title: titleText,
          })
        }
      >
        <FontAwesomeIcon icon={faSortAlphaUp} opacity=".8" />
        <span className={styles.sortByText}>{t('sort')} Z-A</span>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.filterText}>{t('searchByName')}</div>

      <input
        type="text"
        value={titleText || ''}
        onChange={setTitle}
        className={styles.inputBox}
        name="search-livepoll"
        autoComplete="off"
      />
      <span className={styles.searchIconBox}>
        <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
      </span>

      {displayFetchedLivePolls && (
        <div className={styles.searchedLivePolls}>
          {searchedLivePolls.map((livePollTitle: { title: string }, index) => {
            return (
              <div key={index} className={styles.livePollTitle}>
                {livePollTitle.title}
              </div>
            );
          })}
        </div>
      )}
      {isLoading && <LpSpinner />}

      {displayNoLivPollMessage && (
        <div className={styles.noLivePollFound}>{t('noLivePollFound')}</div>
      )}
      <div className={styles.divider}></div>

      <div className={styles.buttons}>
        <div className={styles.clearButton} onClick={clear}>
          {t('clear')}
        </div>
        <Button
          disabled={
            isLoading || titleText === null || searchedLivePolls.length === 0
          }
          customBtnClass={styles.applyButton}
          text={t('apply')}
          onClick={() => filterLivePolls()}
        />
      </div>
    </div>
  );
};
