import React from 'react';
import styles from './Card.module.css';

interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
}

export default class CardSubtitle extends React.Component<Props> {
  public render() {
    const { onClick, children } = this.props;

    return (
      <div className={styles.cardSubtitle} onClick={onClick}>
        {children}
      </div>
    );
  }
}
