import React from 'react';
import styles from './Card.module.css';
import { CardContext } from './CardContext';

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
}

export default class CardFooter extends React.Component<Props> {
  public render() {
    const { onClick } = this.props;
    const hoverContext: { hoverValue: boolean; hover: boolean } = this
      .context as any;
    const hoverEnabled = hoverContext.hoverValue;

    return (
      <div className={styles.cardFooter} onClick={onClick}>
        {!hoverEnabled && this.renderFooter()}
        {hoverEnabled && hoverContext.hover && hoverContext.hoverValue}
        {hoverEnabled && !hoverContext.hover && this.renderFooter()}
      </div>
    );
  }

  private renderFooter(): any {
    const { children } = this.props;
    return <>{children}</>;
  }
}

CardFooter.contextType = CardContext;
