export interface ITemplate {
  id: number;
  template_id: number;
  title: string;
  type: ITemplateType;
  questionCount: number;
  category: string;
}

export enum ITemplateType {
  QUIZ,
  POLL,
}
