import { matchPath } from 'react-router-dom';
import {
  APP_ORIGIN_KEY_IN_LOCAL_STORAGE,
  QP_DOMAIN,
  SHARABLE_DASHBOARD_URL,
} from 'src/constants/app.constants';

export const matchUrls = (firstUrl: string, secondURl: string): boolean => {
  return matchPath({ path: secondURl, end: true }, firstUrl) != null;
};

/**
 * Call this method to get the full URL with the correct QP_DOMAIN
 * @param urlPath
 */
export const getFullQualifiedQpUrl = (urlPath: string): string => {
  const appOrigin = localStorage.getItem(APP_ORIGIN_KEY_IN_LOCAL_STORAGE);

  if (appOrigin) {
    return appOrigin + urlPath;
  }

  return QP_DOMAIN + urlPath;
};

export const getSharableDashboardUrl = (dashboardUid: string): string =>
  `${SHARABLE_DASHBOARD_URL}/${dashboardUid}` || '';
