import React from 'react';
import styles from './Input.module.css';

interface Props {
  text: string;
  fullWidth?: boolean;
  description?: string;
  markRequired?: boolean;
  children: React.ReactNode;
}

export class InputLabel extends React.Component<Props> {
  public render() {
    const { text, children, fullWidth, description, markRequired } = this.props;
    const displayStyle =
      typeof fullWidth === 'undefined' || fullWidth === true
        ? undefined
        : { display: 'inline-block' };
    return (
      <div className={styles.container} style={displayStyle}>
        <label className={styles.label}>
          {text}
          {markRequired && <span className={styles.required}> *</span>}
          {description && ': '}
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </label>
        {children}
      </div>
    );
  }
}
