import { faSpinner, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateLivePollListMutation } from 'src/hooks/store-hooks';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import { LivePollLisStarred } from 'src/models/livepoll-list-starred.enum';
import { ILivePoll } from 'src/models/livepoll.interface';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import styles from './LivePollStarUnStar.module.css';
import { LivePollsView } from 'src/models/livepolls-view.enum';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';

interface Props {
  sortingParams: IFetchLivePollsSortingParams;
  paginationParams: IPaginateDropdown;
  livePoll: ILivePoll;
}

const toggleStarred = (
  currentStarredState: LivePollLisStarred,
): LivePollLisStarred => {
  return currentStarredState === LivePollLisStarred.STARRED
    ? LivePollLisStarred.UN_STARRED
    : LivePollLisStarred.STARRED;
};
export const LivePollStarUnStar = ({
  sortingParams,
  paginationParams,
  livePoll,
}: Props) => {
  const {
    mutate: updateLivePollMutation,
    isLoading: isUpdateLivePollMutationLoading,
    isError: isUpdateLivePollMutationError,
    error: updateLivePollMutationError,
  } = useUpdateLivePollListMutation();

  if (isUpdateLivePollMutationError) {
    throw updateLivePollMutationError;
  }

  const handleToggleLivePollStar = (
    livePollId: number,
    currentStarredState: LivePollLisStarred,
  ) => {
    updateLivePollMutation({
      livePollId,
      starred: toggleStarred(currentStarredState),
      livePollsQueryParams: {
        sortBy: sortingParams.sortBy,
        sortOrder: sortingParams.sortOrder,
        viewType: LivePollsView.LIST,
        pageIndex: paginationParams.pageIndex,
        perPage: paginationParams.perPage,
      },
    });
  };
  return (
    <>
      {isUpdateLivePollMutationLoading && (
        <FontAwesomeIcon
          icon={faSpinner}
          size="lg"
          color="#ccc"
          spin={true}
          data-testid="spinner"
        />
      )}
      {!isUpdateLivePollMutationLoading && (
        <FontAwesomeIcon
          icon={livePoll.starred ? faStar : faStarRegular}
          size="lg"
          className={
            styles.starIcon +
            ' ' +
            (livePoll.starred ? styles.starIconColor : ' ')
          }
          onClick={() =>
            handleToggleLivePollStar(livePoll.id, livePoll.starred!)
          }
          data-testid="livePolls-table-star-icon"
        />
      )}
    </>
  );
};
