import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { IAnswer } from '@livepolls/ui-components/src/interfaces/answer.interface';
import { IChoiceQuestion } from '@livepolls/ui-components/src/interfaces/choice-question.interface';
import { ILiveResultChartType } from '@livepolls/ui-components/src/interfaces/live-result-type.interface';
import { IQuestion } from '@livepolls/ui-components/src/interfaces/question.interface';
import { SwipeQuestionCard } from '@livepolls/ui-components/src/interfaces/swipe-question-card.interface';
import {
  GET_MAPPED_LP_FROM_SLIDES_MSG,
  MAP_LP_TO_SLIDES_MSG,
  UPDATE_DRAFT_LP_MSG,
} from 'src/constants/chrome-extension-msg.constants';
import { updateLivePoll } from 'src/hooks/store-hooks';
import { IAddGeneratedQuestions } from 'src/models/add-generated-questions.interface';
import { IAddProfileQuestion } from 'src/models/add-profile-question.interface';
import { IAddQuestion } from 'src/models/add-question.interface';
import { ILivePollSetting } from 'src/models/livepoll-setting.interface';
import { ILivePollWithStats } from 'src/models/livepoll-total-respondents-and-sessions.interface';
import {
  ILivePoll,
  ILivePollWithPermanentRoom,
} from 'src/models/livepoll.interface';
import { IProfileQuestionAnswer } from 'src/models/profile-question-answer.interface';
import { IProfileQuestionWithAnswers } from 'src/models/profile-question-with-answer.interface';
import { IProfileQuestion } from 'src/models/profile-question.interface';
import { IQuestionFact } from 'src/models/question-fact.interface';
import { SwipeQuestionCardSide } from 'src/models/swipe-question-card-side.enum';
import { ISwipeQuestion } from 'src/models/swipe-question.interface';
import { IWordCloudQuestion } from 'src/models/word-cloud-question.interface';
import { sendMsgToExtension } from 'src/utils/chrome-extension.utils';
import {
  addAIGeneratedQuestions,
  addProfileQuestion,
  addQuestion,
  deleteProfileQuestion,
  deleteQuestion,
  deleteQuestionFact,
  reorderProfileQuestions,
  reorderQuestions,
  setLiveResultChartType,
  updateAIGeneratedQuestionsReviewStatus,
  updatePermanentRoom,
  updateProfileQuestion,
  updateQuestion,
} from 'src/utils/edit-screen.util';
import {
  addGeneratedQuestionsToList,
  addNewProfileQuestionToList,
  addNewQuestionToList,
  createAnswersForScaleQuestion,
  deleteFact,
  deleteProfileQuestionFromList,
  deleteQuestionFromList,
  findAnswerByIdOrThrow,
  findAnswerIndexByIdOrThrow,
  findProfileQuestionAnswerIndexByIdOrThrow,
  findProfileQuestionByIdOrThrow,
  findQuestionByIdOrThrow,
  handleError,
  updateReviewStatusOfAIGeneratedQuestion,
} from 'src/utils/LivePollStore.utils';
import { v4 as uuid } from 'uuid';
import create from 'zustand';

export interface ILivePollEditorStore {
  livePoll?: ILivePoll | ILivePollWithPermanentRoom;
  activeQuestionId?: string;
  activeProfileQuestionId?: string;
  focusQuestionId?: string;
  focusAnswerId?: string;
  isError: boolean;
  isSaving: boolean;
  isLoading: boolean;
  mappedLivePollOnSlides: ILivePollWithStats | null;
  error?: { message: string };
  findQuestion: (questionId: string) => IQuestion;
  findProfileQuestion: (profileQuestionId: string) => IProfileQuestion;
  findAnswerList: (questionId: string) => IAnswer[];
  findSwipeQuestionCards: (questionId: string) => SwipeQuestionCard[];
  findProfileQuestionAnswerList(profileQuestionId: string): any;
  findQuestionImage: (questionId: string) => { url: string } | undefined;
  findProfileQuestionImage: (questionId: string) => { url: string } | undefined;
  findAnswer: (questionId: string, answerId: string) => IAnswer;
  findProfileQuestionAnswer: (
    profileQuestionId: string,
    answerId: string,
  ) => IAnswer;
  findQuestionPresenterNotes: (questionId: string) => string | undefined;
  findQuestionFacts: (questionId: string) => IQuestionFact[] | undefined;
  findCurrentQuestionNumberAndTotalQuestionCount: (questionId: string) => {
    totalQuestionCount: number;
    currentQuestionNumber: number;
  };
  setError: (error: { message: string }) => void;
  setIsSaving: (isSaving: boolean) => void;
  setLivePoll: (livePoll: ILivePoll) => void;
  resetStore: () => void;
  updateLivePollTitleMutation: (livePollTitle: string) => void;
  updateQuestionTitleMutation: (questionId: string, title: string) => void;

  updateSwipeQuestionPropertiesMutation: (
    questionId: string,
    updates: {
      leftSideText?: string;
      rightSideText?: string;
      leftSideColor?: string;
      rightSideColor?: string;
    },
  ) => void;
  updateProfileQuestionTitleMutation: (
    profileQuestionId: string,
    title: string,
  ) => void;
  updateQuestionImageMutation: (questionId: string, imageUrl: string) => void;
  updatePermanentRoomToggleMutation: (
    sessionId?: number,
    livePollTitle?: string,
  ) => void;
  updateProfileQuestionImageMutation: (
    profileQuestionId: string,
    imageUrl: string,
  ) => void;

  setActiveQuestionId: (questionId: string) => void;
  setActiveProfileQuestionId: (questionId: string) => void;
  setFocusQuestionId: (questionId: string | undefined) => void;
  setFocusAnswerId: (answerId: string | undefined) => void;
  addAnswerMutation: (livePollId: number, questionId: string) => void;
  addSwipeQuestionCardMutation: (questionId: string) => void;
  markQuestionAsMultiSelect: (
    questionId: string,
    isMultiSelect: boolean,
  ) => void;
  addProfileQuestionAnswerMutation: (
    livePollId: number,
    profileQuestionId: string,
  ) => void;
  addQuestionMutation: (
    request: IAddQuestion,
    isGoogleSlidesUrlActive: boolean,
  ) => void;
  addGeneratedQuestionsMutation: (
    request: IAddGeneratedQuestions,
    isGoogleSlidesUrlActive: boolean,
  ) => void;
  addProfileQuestionMutation: (request: IAddProfileQuestion) => void;

  deleteQuestionMutation: (
    questionId: string,
    isGoogleSlidesUrlActive: boolean,
  ) => void;
  deleteProfileQuestionMutation: (profileQuestionId: string) => void;
  updateAnswerMutation: (
    livePollId: number,
    questionId: string,
    answerId: string,
    updates: { text?: string; isCorrect?: boolean; image?: { url: string } },
  ) => void;
  updateProfileQuestionAnswerMutation: (
    livePollId: number,
    profileQuestionId: string,
    answerId: string,
    updates: { text?: string; isCorrect?: boolean; image?: { url: string } },
  ) => void;
  deleteAnswerMutation: (questionId: string, answerId: string) => void;
  deleteProfileQuestionAnswerMutation: (
    profileQuestionId: string,
    answerId: string,
  ) => void;
  deleteSwipeQuestionCardMutation: (questionId: string, cardId: string) => void;
  reorderAnswerListMutation: (
    questionId: string,
    orderedAnswerList: IAnswer[],
  ) => void;
  reorderProfileQuestionAnswerListMutation: (
    profileQuestionId: string,
    orderedAnswerList: IProfileQuestionAnswer[],
  ) => void;
  reorderQuestionListMutation: (orderedAnswerList: IQuestion[]) => void;
  reorderProfileQuestionListMutation: (
    orderedAnswerList: IProfileQuestion[],
  ) => void;
  updateLivePollSettingMutation: (setting: ILivePollSetting) => void;
  findDifficultyLevel: (
    questionId: string,
  ) => QuestionDifficultyLevel | undefined;
  updateQuestionDifficultyLevelMutation: (
    questionId: string,
    difficultyLevel: QuestionDifficultyLevel,
  ) => void;
  updateWordCloudQuestionResponseLimitMutation: (
    questionId: string,
    responseLimit: number,
  ) => void;
  updateGeneratedQuestionsReviewStatusMutation: () => void;
  updateLiveResultChartTypeMutation: (
    questionId: string,
    type: ILiveResultChartType,
  ) => void;
  addOptionsForScaleQuestionMutation: (
    questionId: string,
    totalOptions: number,
  ) => void;
  findLiveResultChartType: (
    questionId: string,
  ) => ILiveResultChartType | undefined;
  updateQuestionPresenterNotesMutation: (
    questionId: string,
    presenterNotes: string,
  ) => void;
  deleteQuestionFactMutation: (questionId: string, factId: string) => void;
  addQuestionFactsWithAiMutation: (
    questionId: string,
    facts: IQuestionFact[],
  ) => void;
  setMappedLivePollFromSlide: () => void;
  mapLivePollToSlide: (
    livePoll: ILivePollWithStats,
    navigateToEditorFn?: () => void,
  ) => void;
  updateSwipeQuestionCardMutation: (
    questionId: string,
    cardId: string,
    updates: {
      image?: { url: string };
      correctSide?: SwipeQuestionCardSide;
      title?: string;
    },
  ) => void;
  swipeQuestionTextSelector: (
    questionId: string,
    side: SwipeQuestionCardSide,
  ) => string;
}

export const useLivePollEditorStore = create<ILivePollEditorStore>(
  (set, get) => ({
    isError: false,
    isSaving: false,
    isLoading: false,
    mappedLivePollOnSlides: null,

    findQuestion: (questionId: string): IQuestion => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      );
      return question;
    },

    findProfileQuestion: (profileQuestionId: string): IProfileQuestion => {
      const profileQuestion = findProfileQuestionByIdOrThrow(
        profileQuestionId,
        get().livePoll?.profileQuestions,
      );
      return profileQuestion;
    },

    findQuestionImage: (questionId: string): { url: string } | undefined => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      );
      return question.image;
    },

    findProfileQuestionImage: (
      profileQuestionId: string,
    ): { url: string } | undefined => {
      const profileQuestion = findQuestionByIdOrThrow(
        profileQuestionId,
        get().livePoll?.profileQuestions,
      );
      return profileQuestion.image;
    },
    findAnswerList: (questionId: string): IAnswer[] => {
      const question = get().findQuestion(questionId) as IChoiceQuestion;
      return question.answers;
    },

    findProfileQuestionAnswerList: (profileQuestionId: string): IAnswer[] => {
      const profileQuestion = get().findProfileQuestion(
        profileQuestionId,
      ) as IChoiceQuestion;
      return profileQuestion.answers;
    },

    findAnswer: (questionId: string, answerId: string): IAnswer => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      ) as IChoiceQuestion;
      return findAnswerByIdOrThrow(answerId, question.answers);
    },
    findProfileQuestionAnswer: (
      profileQuestionId: string,
      answerId: string,
    ): IAnswer => {
      const profileQuestion = findProfileQuestionByIdOrThrow(
        profileQuestionId,
        get().livePoll?.profileQuestions,
      ) as IChoiceQuestion;
      return findAnswerByIdOrThrow(answerId, profileQuestion.answers);
    },
    findSwipeQuestionCards: (questionId: string): SwipeQuestionCard[] => {
      const question = get().findQuestion(questionId) as ISwipeQuestion;
      return question.cards;
    },
    findQuestionPresenterNotes: (questionId: string): string | undefined => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      );
      return question.presenterNotes?.notes || '';
    },

    findQuestionFacts: (questionId: string): IQuestionFact[] | undefined => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      );
      return question.presenterNotes?.facts;
    },

    findCurrentQuestionNumberAndTotalQuestionCount: (
      questionId: string,
    ): { currentQuestionNumber: number; totalQuestionCount: number } => {
      const totalQuestionCount = get().livePoll?.questions?.length || 0;
      const index = get().livePoll?.questions?.findIndex(
        question => question.id === questionId,
      );
      const currentQuestionNumber = (index || 0) + 1;
      return { totalQuestionCount, currentQuestionNumber };
    },

    setError: (error: { message: string }) =>
      set(state => {
        state.isError = true;
        state.error = error;
      }),
    setIsSaving: (isSaving: boolean) =>
      set(state => {
        state.isSaving = isSaving;
      }),

    setLivePoll: (livePoll: ILivePoll) =>
      set(state => {
        state.livePoll = JSON.parse(JSON.stringify(livePoll));
      }),

    resetStore: () =>
      set(state => {
        state.livePoll = undefined;
        state.activeQuestionId = undefined;
        state.focusQuestionId = undefined;
        state.focusAnswerId = undefined;
        state.isError = false;
        state.error = undefined;
      }),

    updateLivePollSettingMutation: (setting: ILivePollSetting) =>
      set(state => {
        handleError(async () => {
          if (!state.livePoll) return;

          const livePollId = state.livePoll.id;

          if (state.livePoll?.setting != null) {
            state.livePoll.setting = {
              ...state.livePoll.setting,
              ...setting,
            };
          } else {
            state.livePoll!.setting = { ...setting };
          }

          await updateLivePoll(livePollId, { ...setting });
        }, get().setError);
      }),

    updateLivePollTitleMutation: (livePollTitle: string) =>
      set((state: ILivePollEditorStore) => {
        const livePollId = state.livePoll?.id!;

        if (!state.livePoll) return;

        state.livePoll!.title = livePollTitle;
        handleError(async () => {
          await updateLivePoll(livePollId, { title: livePollTitle });
        }, get().setError);
      }),

    updateQuestionTitleMutation: (questionId: string, title: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(questionId, questions);
          question.title = title;

          await updateQuestion(livePollId, questionId, { title });
        }, get().setError);
      }),

    updateSwipeQuestionCardMutation: (
      questionId: string,
      cardId: string,
      updates: {
        image?: { url: string };
        correctSide?: SwipeQuestionCardSide;
        title?: string;
      },
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const swipeQuestion = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions,
          ) as ISwipeQuestion;

          const updatedCards = swipeQuestion.cards.map(card => {
            if (card.id === cardId) {
              return {
                ...card,
                ...updates,
              };
            }
            return card;
          });
          swipeQuestion.cards = updatedCards;

          await updateQuestion(livePollId, questionId, {
            swipeQuestionUpdates: { cards: [...swipeQuestion.cards] },
          });
        }, get().setError);
      }),

    updateSwipeQuestionPropertiesMutation: (
      questionId: string,
      updates: {
        leftSideText?: string;
        rightSideText?: string;
        leftSideColor?: string;
        rightSideColor?: string;
      },
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(
            questionId,
            questions,
          ) as ISwipeQuestion;
          const leftSideColor = updates.leftSideColor;
          const rightSideColor = updates.rightSideColor;
          const leftSideText = updates.leftSideText;
          const rightSideText = updates.rightSideText;

          if (leftSideColor) {
            question.swipeOptions.left.color = leftSideColor;
          }

          if (leftSideText != null) {
            question.swipeOptions.left.text = leftSideText;
          }

          if (rightSideColor) {
            question.swipeOptions.right.color = rightSideColor;
          }

          if (rightSideText != null) {
            question.swipeOptions.right.text = rightSideText;
          }

          await updateQuestion(livePollId, questionId, {
            swipeQuestionUpdates: { ...updates },
          });
        }, get().setError);
      }),

    updateProfileQuestionTitleMutation: (
      profileQuestionId: string,
      title: string,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const profileQuestions = state.livePoll?.profileQuestions!;
          const profileQuestion = findProfileQuestionByIdOrThrow(
            profileQuestionId,
            profileQuestions,
          );
          profileQuestion.title = title;

          await updateProfileQuestion(livePollId, profileQuestionId, { title });
        }, get().setError);
      }),

    updateQuestionImageMutation: (questionId: string, imageUrl: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(questionId, questions);
          question.image = { ...question.image, url: imageUrl };

          await updateQuestion(livePollId, questionId, {
            image: { url: imageUrl },
          });
        }, get().setError);
      }),

    updateProfileQuestionImageMutation: (
      profileQuestionId: string,
      imageUrl: string,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const profileQuestions = state.livePoll?.profileQuestions!;
          const profileQuestion = findProfileQuestionByIdOrThrow(
            profileQuestionId,
            profileQuestions,
          );
          profileQuestion.image = { ...profileQuestion.image, url: imageUrl };

          await updateProfileQuestion(livePollId, profileQuestionId, {
            image: { url: imageUrl },
          });
        }, get().setError);
      }),

    updatePermanentRoomToggleMutation: (
      sessionId?: number,
      livePollTitle?: string,
    ) =>
      handleError(async () => {
        const livePoll = get().livePoll as ILivePollWithPermanentRoom;
        const livePollId = livePoll?.id!;

        const roomId = livePoll.permanentRoom.id;
        const enable = !livePoll.permanentRoom.enable;

        const permanentRoom = await updatePermanentRoom(
          livePollId,
          roomId,
          enable,
          sessionId,
          livePollTitle,
        );

        set({ livePoll: { ...livePoll, permanentRoom } });
      }, get().setError),

    setActiveQuestionId: (questionId: string) =>
      set(state => {
        state.activeQuestionId = questionId;
      }),
    setActiveProfileQuestionId: (profileQuestionId: string) =>
      set(state => {
        state.activeProfileQuestionId = profileQuestionId;
      }),

    setFocusQuestionId: (questionId: string | undefined) =>
      set({ focusQuestionId: questionId }),

    setFocusAnswerId: (answerId: string | undefined) =>
      set({ focusAnswerId: answerId }),

    addQuestionMutation: (
      updates: IAddQuestion,
      isGoogleSlidesUrlActive: boolean,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const updatedQuestionList = addNewQuestionToList(
            state.livePoll?.questions!,
            updates,
          );

          state.livePoll!.questions! = updatedQuestionList;

          if (isGoogleSlidesUrlActive) {
            sendMsgToExtension(UPDATE_DRAFT_LP_MSG);
          }

          await addQuestion(updates);
        }, get().setError);
      }),

    addGeneratedQuestionsMutation: (
      updates: IAddGeneratedQuestions,
      isGoogleSlidesUrlActive: boolean,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const questions = addGeneratedQuestionsToList(
            state.livePoll?.questions!,
            updates,
          );

          state.livePoll!.questions = [...questions];

          if (isGoogleSlidesUrlActive) {
            sendMsgToExtension(UPDATE_DRAFT_LP_MSG);
          }

          await addAIGeneratedQuestions(updates);
        }, get().setError);
      }),

    addProfileQuestionMutation: (updates: IAddProfileQuestion) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          state.livePoll!.profileQuestions! = addNewProfileQuestionToList(
            state.livePoll?.profileQuestions!,
            updates,
          );

          await addProfileQuestion(updates);
        }, get().setError);
      }),

    deleteQuestionMutation: (
      questionId: string,
      isGoogleSlidesUrlActive: boolean,
    ) => {
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;

          const updatedQuestionList = deleteQuestionFromList(
            state.livePoll?.questions!,
            questionId,
          );
          state.livePoll!.questions! = updatedQuestionList;

          if (isGoogleSlidesUrlActive) {
            sendMsgToExtension(UPDATE_DRAFT_LP_MSG);
          }

          await deleteQuestion(livePollId, questionId);
        }, get().setError);
      });
    },
    deleteProfileQuestionMutation: (profileQuestionId: string) => {
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          state.livePoll!.profileQuestions! = deleteProfileQuestionFromList(
            state.livePoll?.profileQuestions!,
            profileQuestionId,
          );
          await deleteProfileQuestion(livePollId, profileQuestionId);
        }, get().setError);
      });
    },
    addAnswerMutation: (livePollId: number, questionId: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const questions = state.livePoll?.questions;

          if (!questions) throw new Error('question list is empty');

          const questionIndex = questions.findIndex(
            question => question.id === questionId,
          );
          const choiceQuestion = questions[questionIndex] as IChoiceQuestion;

          if (!choiceQuestion) throw new Error('question not found');

          const newAnswer = {
            id: uuid(),
            isCorrect: false,
            image: { url: '' },
          };
          choiceQuestion.answers = [...choiceQuestion.answers, newAnswer];
          state.focusAnswerId = newAnswer.id;

          await updateQuestion(livePollId, questionId, {
            answers: [...choiceQuestion.answers],
          });
        }, get().setError);
      }),

    addSwipeQuestionCardMutation: (questionId: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const questions = state.livePoll?.questions;
          const livePollId = state.livePoll?.id!;
          if (!questions) throw new Error('question list is empty');

          const questionIndex = questions.findIndex(
            question => question.id === questionId,
          );
          const swipeQuestion = questions[questionIndex] as ISwipeQuestion;
          if (!swipeQuestion) throw new Error('question not found');

          const newCard = {
            id: uuid(),
            correctSide: SwipeQuestionCardSide.RIGHT,
            image: { url: '' },
            title: '',
          };
          swipeQuestion.cards = [...swipeQuestion.cards, newCard];

          await updateQuestion(livePollId, questionId, {
            swipeQuestionUpdates: { cards: [...swipeQuestion.cards] },
          });
        }, get().setError);
      }),

    addProfileQuestionAnswerMutation: (
      livePollId: number,
      profileQuestionId: string,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const profileQuestions = state.livePoll?.profileQuestions;

          if (!profileQuestions) throw new Error('question list is empty');

          const questionIndex = profileQuestions.findIndex(
            profileQuestion => profileQuestion.id === profileQuestionId,
          );
          const profileQuestion = profileQuestions[
            questionIndex
          ] as IChoiceQuestion;

          if (!profileQuestion) throw new Error('question not found');

          const newAnswer = {
            id: uuid(),
            isCorrect: false,
            image: { url: '' },
          };
          profileQuestion.answers = [...profileQuestion.answers, newAnswer];
          state.focusAnswerId = newAnswer.id;

          await updateProfileQuestion(livePollId, profileQuestionId, {
            answers: [...profileQuestion.answers],
          });
        }, get().setError);
      }),
    updateAnswerMutation: (
      livePollId: number,
      questionId: string,
      answerId: string,
      updates: { text?: string; isCorrect?: boolean; image?: { url: string } },
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const choiceQuestion = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions,
          ) as IChoiceQuestion;

          const updatedAnswers = choiceQuestion.answers.map(answer => {
            if (answer.id === answerId) {
              return {
                ...answer,
                ...updates,
              };
            }
            return answer;
          });
          choiceQuestion.answers = updatedAnswers;

          await updateQuestion(livePollId, questionId, {
            answers: [...choiceQuestion.answers],
          });
        }, get().setError);
      }),

    updateProfileQuestionAnswerMutation: (
      livePollId: number,
      profileQuestionId: string,
      answerId: string,
      updates: { text?: string; image?: { url: string } },
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const profileQuestion = findProfileQuestionByIdOrThrow(
            profileQuestionId,
            state.livePoll?.profileQuestions,
          ) as IChoiceQuestion;

          const updatedAnswers = profileQuestion.answers.map(answer => {
            if (answer.id === answerId) {
              return {
                ...answer,
                ...updates,
              };
            }
            return answer;
          });
          profileQuestion.answers = updatedAnswers;

          await updateProfileQuestion(livePollId, profileQuestionId, {
            answers: [...profileQuestion.answers],
          });
        }, get().setError);
      }),
    deleteAnswerMutation: (questionId: string, answerId: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const question = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions,
          ) as IChoiceQuestion;

          const answers = question.answers;
          if (answers.length <= 2) {
            return;
          }

          const deletedAnswerIndex = findAnswerIndexByIdOrThrow(
            answerId,
            answers,
          );
          const focusNextAnswerIndex = Math.min(
            answers.length - 2,
            deletedAnswerIndex,
          );

          const updatedAnswers = answers.filter(a => a.id !== answerId);
          question.answers = updatedAnswers;

          const focusNextAnswer = updatedAnswers[focusNextAnswerIndex];

          state.focusAnswerId = focusNextAnswer.id;
          await updateQuestion(livePollId, questionId, {
            answers: updatedAnswers,
          });
        }, get().setError);
      }),

    deleteSwipeQuestionCardMutation: (questionId: string, cardId: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const question = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions,
          ) as ISwipeQuestion;

          const cards = question.cards;
          if (cards.length <= 1) {
            return;
          }

          const updatedCards = cards.filter(card => card.id !== cardId);
          question.cards = updatedCards;
          await updateQuestion(livePollId, questionId, {
            swipeQuestionUpdates: { cards: updatedCards },
          });
        }, get().setError);
      }),

    deleteProfileQuestionAnswerMutation: (
      profileQuestionId: string,
      answerId: string,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const profileQuestion = findProfileQuestionByIdOrThrow(
            profileQuestionId,
            state.livePoll?.profileQuestions,
          ) as IChoiceQuestion;

          const answers = profileQuestion.answers;
          if (answers.length <= 2) {
            return;
          }

          const deletedAnswerIndex = findProfileQuestionAnswerIndexByIdOrThrow(
            answerId,
            answers,
          );
          const focusNextAnswerIndex = Math.min(
            answers.length - 2,
            deletedAnswerIndex,
          );

          const updatedAnswers = answers.filter(a => a.id !== answerId);
          profileQuestion.answers = updatedAnswers;

          const focusNextAnswer = updatedAnswers[focusNextAnswerIndex];

          state.focusAnswerId = focusNextAnswer.id;
          await updateProfileQuestion(livePollId, profileQuestionId, {
            answers: updatedAnswers,
          });
        }, get().setError);
      }),

    reorderAnswerListMutation: (
      questionId: string,
      orderedAnswerList: IAnswer[],
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const question = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions,
          ) as IChoiceQuestion;

          question.answers = orderedAnswerList;

          await updateQuestion(livePollId, questionId, {
            answers: orderedAnswerList,
          });
        }, get().setError);
      }),

    reorderProfileQuestionAnswerListMutation: (
      profileQuestionId: string,
      orderedAnswerList: IProfileQuestionAnswer[],
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const profileQuestion = findProfileQuestionByIdOrThrow(
            profileQuestionId,
            state.livePoll?.profileQuestions,
          ) as IProfileQuestionWithAnswers;

          profileQuestion.answers = orderedAnswerList;

          await updateProfileQuestion(livePollId, profileQuestionId, {
            answers: orderedAnswerList,
          });
        }, get().setError);
      }),
    reorderQuestionListMutation: (orderedQuestionList: IQuestion[]) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          if (state.livePoll) {
            state.livePoll.questions = orderedQuestionList;
          }
          await reorderQuestions(livePollId, orderedQuestionList);
        }, get().setError);
      }),
    reorderProfileQuestionListMutation: (
      orderedQuestionList: IProfileQuestion[],
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          if (state.livePoll) {
            state.livePoll.profileQuestions = orderedQuestionList;
          }
          await reorderProfileQuestions(livePollId, orderedQuestionList);
        }, get().setError);
      }),
    findDifficultyLevel: (
      questionId: string,
    ): QuestionDifficultyLevel | undefined => {
      const question = get().findQuestion(questionId) as IChoiceQuestion;
      return question.difficultyLevel;
    },
    updateQuestionDifficultyLevelMutation: (
      questionId: string,
      difficultyLevel: QuestionDifficultyLevel,
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(
            questionId,
            questions,
          ) as IChoiceQuestion;
          question.difficultyLevel = difficultyLevel;

          await updateQuestion(livePollId, questionId, { difficultyLevel });
        }, get().setError);
      }),

    updateWordCloudQuestionResponseLimitMutation: (questionId, responseLimit) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(
            questionId,
            questions,
          ) as IWordCloudQuestion;
          question.responseLimit = responseLimit;

          await updateQuestion(livePollId, questionId, { responseLimit });
        }, get().setError);
      }),
    updateGeneratedQuestionsReviewStatusMutation: () =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = updateReviewStatusOfAIGeneratedQuestion(
            state.livePoll?.questions!,
          );
          if (state.livePoll) {
            state.livePoll.questions = questions;
          }
          await updateAIGeneratedQuestionsReviewStatus(livePollId);
        }, get().setError);
      }),
    updateLiveResultChartTypeMutation: (
      questionId: string,
      liveResultChartType: ILiveResultChartType,
    ) => {
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;

          const question = findQuestionByIdOrThrow(
            questionId,
            state.livePoll?.questions as IChoiceQuestion[],
          ) as IChoiceQuestion;
          question.liveResultChartType = liveResultChartType;

          await setLiveResultChartType(
            livePollId,
            questionId,
            liveResultChartType,
          );
        }, get().setError);
      });
    },

    addOptionsForScaleQuestionMutation: (
      questionId: string,
      totalOptions: number,
    ) => {
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const question = createAnswersForScaleQuestion(
            state.livePoll?.questions as IChoiceQuestion[],
            questionId,
            totalOptions,
          );
          const livePollId = state.livePoll?.id!;

          const updates = {
            answers: question!.answers,
            liveResultChartType: ILiveResultChartType.SCALE,
            isMultiSelect: false,
          };

          await updateQuestion(livePollId, questionId, updates);
        }, get().setError);
      });
    },
    findLiveResultChartType: (questionId: string) => {
      const question = findQuestionByIdOrThrow(
        questionId,
        get().livePoll?.questions,
      ) as IChoiceQuestion;
      return question.liveResultChartType;
    },

    markQuestionAsMultiSelect: async (
      questionId: string,
      isMultiSelect: boolean,
    ) => {
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const question = findQuestionByIdOrThrow(
            questionId,
            get().livePoll?.questions,
          ) as IChoiceQuestion;

          question.isMultiSelect = isMultiSelect;
          const livePollId = state.livePoll?.id!;

          await updateQuestion(livePollId, questionId, {
            isMultiSelect,
          });
        }, get().setError);
      });
    },
    updateQuestionPresenterNotesMutation: (questionId: string, notes: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(questionId, questions);
          question.presenterNotes = { ...question.presenterNotes, notes };

          await updateQuestion(livePollId, questionId, {
            presenterNotes: { notes },
          });
        }, get().setError);
      }),

    addQuestionFactsWithAiMutation: (
      questionId: string,
      facts: IQuestionFact[],
    ) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(questionId, questions);

          question.presenterNotes = {
            ...question.presenterNotes,
            facts,
          };
        }, get().setError);
      }),

    deleteQuestionFactMutation: (questionId: string, factId: string) =>
      set((state: ILivePollEditorStore) => {
        handleError(async () => {
          const livePollId = state.livePoll?.id!;
          const questions = state.livePoll?.questions!;
          const question = findQuestionByIdOrThrow(questionId, questions);
          const facts = deleteFact(question.presenterNotes, factId);

          question.presenterNotes = {
            ...question.presenterNotes,
            facts,
          };

          await deleteQuestionFact(livePollId, questionId, factId);
        }, get().setError);
      }),

    setMappedLivePollFromSlide: async () => {
      try {
        set({ isLoading: true });
        const extensionResponse = await sendMsgToExtension(
          GET_MAPPED_LP_FROM_SLIDES_MSG,
        );

        if (extensionResponse.isError) {
          throw new Error(extensionResponse.errorMsg);
        }

        const mappedLivePoll = extensionResponse.data?.livePoll;
        if (mappedLivePoll != null) {
          set({ mappedLivePollOnSlides: mappedLivePoll });
        }
      } catch (err) {
        set({ isError: true, error: err as Error });
      } finally {
        set({ isLoading: false });
      }
    },

    mapLivePollToSlide: async (
      livePoll: ILivePollWithStats,
      navigateToEditorFn?: () => void,
    ) => {
      try {
        const extensionResponse = await sendMsgToExtension(
          MAP_LP_TO_SLIDES_MSG,
          {
            livePollId: livePoll.id,
          },
        );
        const { isError } = extensionResponse;

        if (isError) {
          throw new Error(extensionResponse.errorMsg);
        }
        set({ mappedLivePollOnSlides: livePoll });

        navigateToEditorFn && navigateToEditorFn();
      } catch (err) {
        set({ isError: true, error: err as Error });
      }
    },

    swipeQuestionTextSelector: (
      questionId: string,
      side: SwipeQuestionCardSide,
    ) => {
      const swipeQuestion = get().findQuestion(questionId) as ISwipeQuestion;
      if (side === SwipeQuestionCardSide.LEFT) {
        const text = swipeQuestion.swipeOptions.left.text;
        return text;
      }
      const text = swipeQuestion.swipeOptions.right.text;
      return text;
    },
  }),
);
