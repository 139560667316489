import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LpDropdown } from '@livepolls/ui-components/src/components/dropdown/LpDropdown';
import { IPaginateDropdown } from '../../interfaces/paginate-dropdown.interface';
import styles from './LpPaginationDropdown.module.css';

interface Props {
  pager: IPaginateDropdown;
  totalItems: number;
  onPageChange: (pager: IPaginateDropdown) => void;
  enablePerPage?: boolean;
  text: {
    showing: string;
    showUpto: string;
    of: string;
    to: string;
  };
  customDropdownContentClass?: string;
}

const perPageOptions = [10, 25, 50];

const getFirstItemOnCurrentPage = (
  currentPage: number,
  perPage: number,
  totalPages: number,
): number => {
  if (totalPages === 0) {
    return 0;
  }
  return currentPage * perPage - perPage + 1;
};

const getLastItemOnCurrentPage = (
  currentPage: number,
  perPage: number,
  totalItems: number,
): number => {
  const lastItem = currentPage * perPage;
  return lastItem > totalItems ? totalItems : lastItem;
};

export const LpPaginateDropdown = ({
  onPageChange,
  pager,
  totalItems,
  enablePerPage,
  text,
  customDropdownContentClass,
}: Props) => {
  const { pageIndex, perPage } = pager;
  const totalPages = Math.ceil(totalItems / perPage);

  const dropDownText = `${getFirstItemOnCurrentPage(
    pageIndex,
    perPage,
    totalPages,
  )} - ${getLastItemOnCurrentPage(pageIndex, perPage, totalItems)} ${
    text.of
  } ${totalItems}`;

  const handlePageIndexChange = (pageIndex: number) => {
    if (pageIndex <= 0 || pageIndex > totalPages) {
      return;
    }
    onPageChange({ ...pager, pageIndex });
  };

  const handlePerPageChange = (perPage: number) => {
    const firstItem = getFirstItemOnCurrentPage(pageIndex, perPage, totalPages);
    if (firstItem > totalItems || perPage !== pager.perPage) {
      onPageChange({ perPage, pageIndex: 1 });
      return;
    }
    onPageChange({ ...pager, perPage });
  };

  const getPageRangeList = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      const page = (
        <li key={i} onClick={() => handlePageIndexChange(i)}>
          {getFirstItemOnCurrentPage(i, perPage, totalPages)} {text.to}{' '}
          {getLastItemOnCurrentPage(i, perPage, totalItems)}
        </li>
      );
      pages.push(page);
    }
    return pages;
  };
  return (
    <div className={styles.container}>
      <button
        className={`${styles.previousBtn} ${
          pageIndex > 1 ? styles.visible : styles.hidden
        }`}
        onClick={() => handlePageIndexChange(pageIndex - 1)}
        data-testid="previous-page-btn"
      >
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="lg"
          title="go-to-previous-page-icon"
        />
      </button>
      <LpDropdown
        text={dropDownText}
        customDropdownContentClass={customDropdownContentClass}
      >
        <div className={styles.pageRangeContainer}>
          <span className={styles.pageRangeTitle}>{`${text.showing}`}</span>
          <ul className={styles.list}>{getPageRangeList()}</ul>
        </div>
        {!!enablePerPage && (
          <div className={styles.perPageContainer}>
            <span className={styles.perPageTitle}>{text.showUpto}</span>
            <ul className={styles.list}>
              {perPageOptions.map(perPageOption => (
                <li
                  key={perPageOption}
                  onClick={() => handlePerPageChange(perPageOption)}
                >{`${perPageOption}`}</li>
              ))}
            </ul>
          </div>
        )}
      </LpDropdown>
      <button
        className={`${styles.nextBtn} ${
          pageIndex < totalPages ? styles.visible : styles.hidden
        }`}
        onClick={() => handlePageIndexChange(pageIndex + 1)}
        data-testid="next-page-btn"
      >
        <FontAwesomeIcon
          icon={faAngleRight}
          size="lg"
          title="go-to-next-page-icon"
        />
      </button>
    </div>
  );
};
