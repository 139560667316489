import { WuAppHeader } from '@questionproext/wick-ui-lib';
import axios from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  LOGOUT_API_URL,
  QP_LOGOUT_API_URL_PATH,
} from 'src/constants/app.constants';
import { useOptAuth } from 'src/contexts/auth.context';
import { useThirdPartyAppIntegration } from 'src/contexts/ThirdPartyAppsContext';
import { ThirdPartyAppType } from 'src/models/third-party-app-type.enum';
import { useIsPresenterNotesWindowUrlActive } from 'src/utils/hooks.util';
import { redirectTo } from 'src/utils/redirect.util';
import { openUrlInBrowserFromZoomApp } from 'src/utils/zoom-sdk.utils';
import * as AppUrls from '../../components/navbar/app.urls';
import styles from './header.module.css';
import { useIsGoogleSlidesUrlActive } from 'src/utils/hooks.util';
import { getFullQualifiedQpUrl } from 'src/utils/url.util';
import { getApi } from 'src/utils/api.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import downArrow from '../../images/down-arrow.png';
import upArrow from '../../images/up-arrow.png';

const logoutUrl = LOGOUT_API_URL!;
const zoomAppHome = AppUrls.MEETING_APPS_URLS.ZOOM_APP_HOME;

export const Header = () => {
  const { t } = useTranslation();
  const { user } = useOptAuth();
  const [myAccount, setMyAccount] = React.useState(null);
  const [productCategories, setProductCategories] = React.useState(null);
  const [categoriesModal, setCategoriesModal] = React.useState<boolean>(false);
  const [accountModal, setAccountModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const { myAccount, categories } = await fetchHeaderApi();
      setMyAccount(myAccount);
      setProductCategories(categories);
    };
    if (!myAccount || !productCategories) {
      fetchData();
    }
  }, [myAccount, productCategories]);

  const showAccountAndCategories = !!(myAccount && productCategories);
  const [isAdminPathActive, setIsAdminPathActive] =
    React.useState<boolean>(false);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    location.pathname,
  );

  const path = location.pathname;
  const { thirdPartyAppType } = useThirdPartyAppIntegration();
  const isGoogleSlidesUrlActive = useIsGoogleSlidesUrlActive();
  const isPresenterNotesWindowUrlActive = useIsPresenterNotesWindowUrlActive();

  React.useEffect(() => {
    const pathAfterPrefix = '/' + AppUrls.URLS.ADMIN_PERFORMANCE_LOGS_URL;
    if (path === pathAfterPrefix) {
      setIsAdminPathActive(true);
    } else {
      setIsAdminPathActive(false);
    }
  }, [path, isAdminPathActive]);

  React.useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
    }
  }, [currentLocation, location]);

  const logOut = async () => {
    if (thirdPartyAppType === ThirdPartyAppType.ZOOM_APP) {
      await logoutFromZoomApp();
    } else {
      await logoutFromEditApp();
    }
  };

  if (isGoogleSlidesUrlActive || isPresenterNotesWindowUrlActive) {
    return null;
  }

  const handleCategoryModal = () => {
    setCategoriesModal(!categoriesModal);
  };

  const handleAccountModal = () => {
    setAccountModal(!accountModal);
  };

  const customHeader = (
    <div className={styles.customHeader} data-testid="custom header">
      <div className={styles.customHeaderLeftSide}>
        <div className={styles.logoContainer}>
          <span
            className={styles.logo}
            onClick={() => (window.location.href = '/')}
          ></span>
        </div>
        <div className={styles.customHeaderText} onClick={handleCategoryModal}>
          <div>LivePolls</div>
          <div className={styles.downArrow}>
            {!categoriesModal && (
              <img src={downArrow} alt="open dropdown" height={15} width={15} />
            )}
            {categoriesModal && (
              <img src={upArrow} alt="up dropdown" height={15} width={15} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.customHeaderRightSide}>
        <div className={styles.userInitials} onClick={handleAccountModal}>
          <span className={styles.userInitialsText}>{user?.initials}</span>
        </div>
      </div>

      {categoriesModal && (
        <div className={styles.categoryModal}>
          <LpSpinner message="Fetching..." />
        </div>
      )}
      {accountModal && (
        <div className={styles.accountModal}>
          <LpSpinner message="Fetching..." />
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.header}>
      {!showAccountAndCategories && customHeader}
      {showAccountAndCategories && (
        <WuAppHeader
          myAccount={myAccount}
          productCategories={productCategories}
          activeProductName="LivePolls"
          onLogoutClick={() => logOut()}
        />
      )}
      {user?.isAdmin && (
        <Link
          to="/admin/logs"
          className={
            styles.admin +
            ' ' +
            (isAdminPathActive ? styles.activeActivePath : '')
          }
        >
          {t('admin')}
        </Link>
      )}
    </div>
  );
};

const logoutFromEditApp = async () => {
  const qpLogoutUrl = getFullQualifiedQpUrl(QP_LOGOUT_API_URL_PATH);
  try {
    await axios.get(qpLogoutUrl, { withCredentials: true });
    await axios.get(qpLogoutUrl, { withCredentials: true });
  } catch (error) {
  } finally {
    await axios.get(logoutUrl, { withCredentials: true });
    localStorage.clear();
    redirectTo(qpLogoutUrl);
  }
};

const logoutFromZoomApp = async () => {
  const qpLogoutUrl = getFullQualifiedQpUrl(QP_LOGOUT_API_URL_PATH);
  try {
    const isSuccess = await openUrlInBrowserFromZoomApp(qpLogoutUrl);
    if (!isSuccess)
      throw new Error('Error in ZoomSDK. Cannot open url to logout.');
  } catch (error) {
    console.error(error);
  } finally {
    await axios.get(logoutUrl, { withCredentials: true });
    localStorage.clear();
    redirectTo(zoomAppHome);
  }
};

const fetchHeaderApi = async (): Promise<{
  myAccount: any;
  categories: any;
}> => {
  const res = await getApi('/auth/header-info');
  return {
    myAccount: res[1].myAccount,
    categories: res[0].productSwitcher.categories,
  };
};
