import { Link } from 'react-router-dom';
import Button from '../button/Button';
import styles from './Navbar.module.css';
import { NavbarItemType } from './NavbarItemType';

interface Props {
  text: string;
  active?: boolean;
  url?: string;
  onClick?: () => any;
  type?: NavbarItemType;
}

export const NavItem = (props: Props) => {
  let item = (
    <Link to={props.url!} className={props.active ? styles.activeClass : ''}>
      {props.text}
    </Link>
  );
  if (props.type === NavbarItemType.BUTTON) {
    item = <Button text={props.text} onClick={props.onClick!} />;
  }

  return <li>{item}</li>;
};
