import * as React from 'react';
import Style from './ErrorDisplay.module.css';

interface Props {
  error?: Error | undefined;
  componentName: string;
  customHeader?: string;
  disableBackLink?: boolean;
  children?: React.ReactNode;
}

export default class ErrorDisplay extends React.Component<Props> {
  goBack() {
    window.history.go(-1);
    return false;
  }

  render() {
    const { error, componentName, customHeader, disableBackLink } = this.props;

    if (error) {
      return (
        <div className={Style.container}>
          {disableBackLink !== true && (
            <span className={Style.backLink} onClick={this.goBack}>
              Go back
            </span>
          )}

          <h2>
            {customHeader || 'Oops! An error occurred loading page'}
            <small>{`(${componentName})`}</small>.
          </h2>

          {error && (
            <div>
              {error.message && (
                <div>
                  <strong>Message: </strong>
                  <span>{error.message}</span>
                </div>
              )}
              {!error.message && (
                <div>
                  <strong>Message: </strong>
                  <span>{error.message}</span>
                </div>
              )}
              {error.stack && (
                <div>
                  <strong>Details: </strong>
                  <span>{error.stack}</span>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
