import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChartLine,
  faGraduationCap,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { dateStringToFormattedDate } from '@livepolls/ui-components/src/utils/DateTimeUtil';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  URLS,
  goToLivePollEditURLForSlides,
} from 'src/components/navbar/app.urls';
import penImg from 'src/images/pen.svg';
import { LivePollListSortBy } from 'src/models/livepoll-list-sort-by.enum';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import styles from './GoogleSlidesLivePollListTable.module.css';

import { ILivePollWithStats } from 'src/models/livepoll-total-respondents-and-sessions.interface';
import { SearchLivePollsByName } from './SearchLivePollsByName';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { LpPaginateDropdown } from '@livepolls/ui-components/src/components/pagination-dropdown/LpPaginationDropdown';
import { useTranslation } from 'react-i18next';
import { TableHead } from 'src/components/table-head/TableHead';
import { SortOrder } from 'src/models/sort-order.enum';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import {
  errorSelector,
  isErrorSelector,
  mapLivePollToSlideSelector,
} from 'src/stores/livePoll-store.selectors';

interface Props {
  sortingParams: IFetchLivePollsSortingParams;
  pager: IPaginateDropdown;
  totalItems: number;
  livePolls?: ILivePollWithStats[];
  onTableHeadClick: (sortingParams: IFetchLivePollsSortingParams) => void;
  onPageChange: (pager: IPaginateDropdown) => void;
  searchText: string | null;
  mappedLivePollId?: number;
}

export const GoogleSlidesLivePollListTable = ({
  sortingParams,
  livePolls,
  pager,
  totalItems,
  onTableHeadClick,
  onPageChange,
  searchText,
  mappedLivePollId,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useRef(false);

  const [showPopupForName, setShowPopupForName] = useState<boolean>(false);

  const navigate = useNavigate();
  const mapLivePollToSlide = useLivePollEditorStore(mapLivePollToSlideSelector);
  const isError = useLivePollEditorStore(isErrorSelector);
  const error = useLivePollEditorStore(errorSelector);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (isError) {
    throw error;
  }

  const handleSortColumn = (sortByColumn: LivePollListSortBy) => {
    if (sortByColumn === LivePollListSortBy.NAME) {
      setShowPopupForName(true);
    } else {
      const sortOrder =
        sortingParams.sortOrder === SortOrder.ASC
          ? SortOrder.DESC
          : SortOrder.ASC;
      onTableHeadClick({ sortBy: sortByColumn, sortOrder, title: searchText });
    }
  };

  const handleEditIconClick = (livePollId: number) => {
    goToLivePollEditURLForSlides(navigate, livePollId);
  };

  const sortIcon = (sortBy: LivePollListSortBy): IconProp => {
    if (sortBy === sortingParams.sortBy) {
      return sortingParams.sortOrder === SortOrder.ASC ? faSortUp : faSortDown;
    } else {
      return faSort;
    }
  };

  const closePopup = () => {
    setShowPopupForName(false);
  };

  const onPopupClick = (params: IFetchLivePollsSortingParams) => {
    const { sortBy, sortOrder, title } = params;
    onTableHeadClick({ sortBy, sortOrder, title });
    closePopup();
  };

  const addLivePollToSlides = async (livePoll: ILivePollWithStats) => {
    // navigate to editor
    const navigateToEditorFn = () => {
      navigate(
        `/${URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL.replace(
          ':livePollId',
          `${livePoll.id}`,
        )}`,
      );
    };

    await mapLivePollToSlide(livePoll, navigateToEditorFn);
  };

  const isNameColumnActive = !!(searchText && searchText?.length > 0);
  return (
    <div className={styles.container}>
      <div className={styles.paginationContainer}>
        <LpPaginateDropdown
          pager={pager}
          onPageChange={onPageChange}
          totalItems={totalItems}
          enablePerPage={true}
          customDropdownContentClass={styles.dropDownContentClass}
          text={{
            showing: t('showing'),
            showUpto: t('showUpTo'),
            of: t('of'),
            to: t('to'),
          }}
        />
      </div>
      <div
        className={`${styles.tableContainer} ${
          totalItems > 10 ? styles.includePagination : ''
        }`}
      >
        <table className={styles.livePollListTable}>
          <thead>
            <tr>
              <TableHead
                text={t('name')}
                className={styles.nameColumnHead}
                onClick={() => handleSortColumn(LivePollListSortBy.NAME)}
                icon={sortIcon(LivePollListSortBy.NAME)}
                active={isNameColumnActive}
              />
              <TableHead
                text={t('type')}
                className={styles.typeColumnHead}
                onClick={() => handleSortColumn(LivePollListSortBy.TYPE)}
                icon={sortIcon(LivePollListSortBy.TYPE)}
              />

              <TableHead
                text={t('lastActive')}
                onClick={() =>
                  handleSortColumn(LivePollListSortBy.LAST_SESSION)
                }
                icon={sortIcon(LivePollListSortBy.LAST_SESSION)}
                className={styles.lastActiveColumnHead}
              />
              <th className={styles.hoverOptionsColumnHead} />
            </tr>
          </thead>

          <tbody>
            {livePolls?.map(livePoll => (
              <tr key={livePoll.id}>
                <td
                  data-testid="livePolls-table-title-data"
                  className={styles.titleCell}
                >
                  <Link
                    to={`/${URLS.LIVE_POLL_EDITOR_FOR_SLIDES_URL.replace(
                      ':livePollId',
                      `${livePoll.id}`,
                    )}`}
                    className={styles.titleLink}
                  >
                    {' '}
                    {livePoll.title ? livePoll.title : t('untitled')}{' '}
                  </Link>
                </td>
                <td
                  className={
                    styles.iconTableData + ' ' + styles.livePollTypeIcon
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      livePoll.type === LivePollType.POLL
                        ? faChartLine
                        : faGraduationCap
                    }
                    size="lg"
                    data-testid="livePolls-table-type-icon"
                  />
                </td>

                <td
                  data-testid="livePolls-table-lastSessionStartedAt-data"
                  className={styles.lastSessionStartedAtTableData}
                >
                  {livePoll.lastSessionStartedAt
                    ? dateStringToFormattedDate(
                        livePoll.lastSessionStartedAt.toString(),
                      )
                    : '-'}
                </td>
                <td className={styles.iconTableData}>
                  <div className={styles.hoverOptions}>
                    {livePoll.id === mappedLivePollId ? (
                      <div>Added to Presentation</div>
                    ) : (
                      <div
                        className={styles.startSessionText}
                        onClick={() => addLivePollToSlides(livePoll)}
                      >
                        Add to Presentation
                      </div>
                    )}

                    <div className={styles.hoverOptions}>
                      <div className={styles.hoverOptionsIcons}>
                        <img
                          src={penImg}
                          alt="edit livepoll"
                          onClick={() => handleEditIconClick(livePoll.id)}
                          data-testid="livePolls-table-goto-edit-icon"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPopupForName && (
        <SearchLivePollsByName
          onTableHeadClick={onTableHeadClick}
          onPopupClick={onPopupClick}
          searchText={searchText}
          sortingParams={sortingParams}
          closePopup={() => closePopup()}
        />
      )}
    </div>
  );
};
