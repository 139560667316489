import { CloseIcon } from '../svgImages/CloseIcon.svg';
import { TickMarkIcon } from '../svgImages/TickMarkIcon.svg';
import styles from './LpToast.module.css';

interface Props {
  text: string;
  onClose: () => void;
}

export const LpToast = ({ text, onClose }: Props) => {
  return (
    <div className={styles.container}>
      <span>
        <TickMarkIcon width={20} height={20} />
      </span>
      <span className={styles.toastText}>{text}</span>
      <span onClick={() => onClose()} className={styles.closeIcon}>
        <CloseIcon width={12} height={12} />
      </span>
    </div>
  );
};
