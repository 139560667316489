import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './contexts/auth.context';

export function AppProviders({ children }: any) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AuthProvider>
  );
}
