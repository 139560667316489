import { IAddGeneratedQuestions } from 'src/models/add-generated-questions.interface';
import { IAddProfileQuestion } from 'src/models/add-profile-question.interface';
import { IAddQuestion } from 'src/models/add-question.interface';
import { IAnswer } from '@livepolls/ui-components/src/interfaces/answer.interface';
import { IChoiceQuestion } from '@livepolls/ui-components/src/interfaces/choice-question.interface';
import { IProfileQuestion } from 'src/models/profile-question.interface';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { IQuestion } from '@livepolls/ui-components/src/interfaces/question.interface';
import { ILiveResultChartType } from '@livepolls/ui-components/src/interfaces/live-result-type.interface';
import { v4 as uuid } from 'uuid';
import { IPresenterNotes } from '@livepolls/ui-components/src/interfaces/presenter-notes.interface';
import { IQuestionFact } from 'src/models/question-fact.interface';

export const addNewQuestionToList = (
  questions: IQuestion[],
  request: IAddQuestion,
): IQuestion[] => {
  const { relativeQuestionId, isAddBeforeQuestionId, question } = request;
  const relativeIndex = questions.findIndex(q => q.id === relativeQuestionId);
  let newQuestionIndex = relativeIndex < 0 ? 0 : relativeIndex;
  // If it's add after question, check index is < (questions length - 1)
  if (!isAddBeforeQuestionId) {
    newQuestionIndex =
      relativeIndex + 1 >= questions.length
        ? questions.length
        : relativeIndex + 1;
  }

  const updatedQuestions = [...questions];
  updatedQuestions.splice(newQuestionIndex, 0, question);
  return updatedQuestions;
};

export const addGeneratedQuestionsToList = (
  questions: IQuestion[],
  request: IAddGeneratedQuestions,
) => {
  const {
    relativeQuestionId,
    isAddBeforeQuestionId,
    questions: aiGeneratedQuestions,
  } = request;
  const relativeIndex = questions.findIndex(q => q.id === relativeQuestionId);
  let newQuestionIndex = relativeIndex < 0 ? 0 : relativeIndex;
  // If it's add after question, check index is < (questions length - 1)
  if (!isAddBeforeQuestionId) {
    newQuestionIndex =
      relativeIndex + 1 >= questions.length
        ? questions.length
        : relativeIndex + 1;
  }

  const updatedQuestions = [...questions];
  updatedQuestions.splice(newQuestionIndex, 0, ...aiGeneratedQuestions);
  return updatedQuestions;
};

export const addNewProfileQuestionToList = (
  profileQuestions: IProfileQuestion[],
  request: IAddProfileQuestion,
): IQuestion[] => {
  if (!profileQuestions) {
    return [request.profileQuestion];
  }
  const { relativeQuestionId, isAddBeforeQuestionId, profileQuestion } =
    request;
  const relativeIndex = profileQuestions.findIndex(
    q => q.id === relativeQuestionId,
  );
  let newQuestionIndex = relativeIndex < 0 ? 0 : relativeIndex;
  // If it's add after question, check index is < (questions length - 1)
  if (!isAddBeforeQuestionId) {
    newQuestionIndex =
      relativeIndex + 1 >= profileQuestions.length
        ? profileQuestions.length
        : relativeIndex + 1;
  }

  const updatedQuestions = [...profileQuestions];
  updatedQuestions.splice(newQuestionIndex, 0, profileQuestion);
  return updatedQuestions;
};

export const updateReviewStatusOfAIGeneratedQuestion = (
  questions: IQuestion[],
) => {
  questions.map(question => {
    const generatedQuestion = question as IChoiceQuestion;
    if (
      generatedQuestion.type === QuestionType.CHOICE &&
      generatedQuestion.generatedByAI
    ) {
      return (generatedQuestion.isReviewed = true);
    }
    return question;
  });
  return questions;
};

export const createAnswersForScaleQuestion = (
  questions: IChoiceQuestion[] | undefined,
  questionId: string,
  totalOptions: number,
) => {
  const question = findQuestionByIdOrThrow(
    questionId,
    questions,
  ) as IChoiceQuestion;
  question.liveResultChartType = ILiveResultChartType.SCALE;

  if (totalOptions === 3) {
    const answers = [
      { id: uuid(), text: 'Disagree', isCorrect: false },
      { id: uuid(), text: 'Neutral', isCorrect: false },
      { id: uuid(), text: 'Agree', isCorrect: false },
    ];
    question.answers = answers;
  } else {
    //Note: this is for 5 Option

    const answers = [
      { id: uuid(), text: 'Strongly Disagree', isCorrect: false },
      { id: uuid(), text: 'Disagree', isCorrect: false },
      { id: uuid(), text: 'Neutral', isCorrect: false },
      { id: uuid(), text: 'Agree', isCorrect: false },
      { id: uuid(), text: 'Strongly Agree', isCorrect: false },
    ];
    question.answers = answers;
  }
  return question;
};

export const deleteQuestionFromList = (
  questions: IQuestion[],
  questionId: string,
): IQuestion[] => {
  return questions.filter(question => question.id !== questionId);
};
export const deleteProfileQuestionFromList = (
  questions: IQuestion[],
  questionId: string,
): IQuestion[] => {
  return questions.filter(question => question.id !== questionId);
};

export const findQuestionByIdOrThrow = (
  questionId: string,
  questions?: IQuestion[],
): IQuestion => {
  if (!questions) throw new Error('question list is empty');

  const questionIndex = questions.findIndex(
    question => question.id === questionId,
  );

  if (questionIndex === -1)
    throw new Error(`no question found with id ${questionId}`);

  return questions[questionIndex];
};

export const findProfileQuestionByIdOrThrow = (
  profileQuestionId: string,
  profileQuestions?: IProfileQuestion[],
): IQuestion => {
  if (!profileQuestions) throw new Error('question list is empty');

  const profileQuestionIndex = profileQuestions.findIndex(
    profileQuestion => profileQuestion.id === profileQuestionId,
  );

  if (profileQuestionIndex === -1)
    throw new Error(`no question found with id ${profileQuestionIndex}`);

  return profileQuestions[profileQuestionIndex];
};

export const findAnswerIndexByIdOrThrow = (
  answerId: string,
  answers: IAnswer[],
): number => {
  const answerIndex = answers.findIndex(answer => answer.id === answerId);

  if (answerIndex === -1)
    throw new Error(`no answer option found with id ${answerId}`);

  return answerIndex;
};

export const findProfileQuestionAnswerIndexByIdOrThrow = (
  answerId: string,
  answers: IAnswer[],
): number => {
  const answerIndex = answers.findIndex(answer => answer.id === answerId);

  if (answerIndex === -1)
    throw new Error(`no answer option found with id ${answerId}`);

  return answerIndex;
};

export const findAnswerByIdOrThrow = (
  answerId: string,
  answers: IAnswer[],
): IAnswer => {
  const answer = answers.find(answer => answer.id === answerId);

  if (!answer) throw new Error(`no answer option found with id ${answerId}`);

  return answer;
};

export const handleError = async (callApi: Function, setError: Function) => {
  try {
    await callApi();
  } catch (err: any) {
    console.error(err);
    setError({ message: err.message });
  }
};

export const deleteFact = (
  presenterNotes: IPresenterNotes | undefined,
  factId: string,
): IQuestionFact[] => {
  const filterFacts = presenterNotes?.facts?.filter(fact => fact.id !== factId);
  return filterFacts || [];
};
