import React from 'react';
import styles from './OptionCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Props {
  text: string;
  icon?: IconDefinition;
  onClick: () => void;
  imagePath?: React.ReactElement;
  optionCardClassName?: string;
  children?: React.ReactNode;
}

export const OptionCard = ({
  text,
  icon,
  onClick,
  imagePath,
  optionCardClassName,
  children,
}: Props) => {
  return (
    <div
      className={`${styles.optionCard} ${optionCardClassName}`}
      onClick={onClick}
    >
      {icon && (
        <div className={styles.icon}>
          <FontAwesomeIcon size="4x" icon={icon} />
        </div>
      )}
      {imagePath && <div className={styles.image}>{imagePath} </div>}
      <div className={styles.name}>{text}</div>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
